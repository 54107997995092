import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const memberRegister = createAsyncThunk("register", async (data, thunkAPI) => {
  return api
    .post("member/register", data)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log(err.response.data.message)
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

export const memberLogin = createAsyncThunk("login", async (data, thunkAPI) => {
  return api
    .post("member/login", data)
    .then(
      (res) => {
        localStorage.setItem("token", JSON.stringify(res.data.tokens));
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});


export const checkLogin = createAsyncThunk("check_login", async (data, thunkAPI) => {
  const tokenJSON = JSON.parse(localStorage.getItem("token"));

  if (tokenJSON) {
    // return api
    // .post("member/refresh-tokens", {
    //   refreshToken: tokenJSON.refresh.token
    // })
    // .then(
    //   (res) => {
    //     localStorage.setItem("token", JSON.stringify(res.data));
    //     return true;
    //   },
    //   (err) => {
    //     return thunkAPI.rejectWithValue(err.response.data.message);
    //   }
    // );
    return true
  } else {
    return false
  }

});

export const getMember = createAsyncThunk("getMember", async (id, thunkAPI) => {
  return api
    .get(`member/get/${id}`)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

const member = createSlice({
  name: "member",
  initialState: {
    data: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLogin: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
    logout: (state, action) => {
      localStorage.removeItem("token");
      state.isLogin = false;
    },
  },
  extraReducers: (builder) => {
    // memberRegister
    builder.addCase(memberRegister.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(memberRegister.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(memberRegister.pending, (state, action) => {
      state.isFetching = true;
    });

    // memberRegister
    builder.addCase(memberLogin.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.isLogin = true;
      state.data = payload.member;
      return state;
    });
    builder.addCase(memberLogin.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(memberLogin.pending, (state, action) => {
      state.isFetching = true;
    });

    builder.addCase(checkLogin.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      // state.isFetching = false;
      // state.isSuccess = false;
      state.isLogin = payload;
      return state;
    });
    builder.addCase(checkLogin.rejected, (state, { payload }) => {
      // state.isFetching = false;
      // state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(checkLogin.pending, (state, action) => {
      // state.isFetching = true;
    });

    // 
    builder.addCase(getMember.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      // state.isSuccess = true;
      state.isLogin = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(getMember.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getMember.pending, (state, action) => {
      state.isFetching = true;
    });

  },
});

export const { clearState, logout } = member.actions
export default member.reducer;
