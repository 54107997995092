import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLowongan } from "../app/module/lowongan/lowonganSlice";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";

import parse from "html-react-parser";
import moment from "moment/moment";

class CarrerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { slug: "" };
  }

  componentDidMount = () => {
    if (this.props.match.params) {
      this.setState({ ...this.state, slug: this.props.match.params.slug });
      this.props.getLowongan({ slug: this.props.match.params.slug });
    }
  };

  render() {
    const { list } = this.props.referensi;
    const { data } = this.props.lowongan;
    const { isLogin } = this.props.member;

    return (
      <>
        <HeaderBlack />

        {/* Search Popup */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        {/* <div style={{marginTop: "-50px", background: "#f5f5f5"}}>
                    <div style={{background: "#f5f5f5"}} className="auto-container">
                        <section className="cases-section">
                            <div className="case-tabs">
                                <div className="container-fluid">
                                    <div className="sec-title text-center">
                                        <div className="sub-title">{ this.props.t('photo') }</div>
                                        <h2> {this.props.t('bisnis')} </h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> */}

        {/* Sidebar Page Container */}
        <div
          style={{
            marginTop: "-60px",
            background: "#f5f5f5",
          }}>
          <div className="sidebar-page-container">
            <div className="auto-container">
              <div className="row">
                <div className="col-lg-7">
                  {/* News Block Two */}
                  <div className="news-block-two blog-single-post">
                    <div className="inner-box">
                      <div className="lower-content">
                        <div>
                          {/* <div className="category">{data.category}</div> */}

                          <h2>{data.title}</h2>
                          <div className="text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parse(data.content),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <aside className="sidebar">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/lowongan/${data.image}`}
                      alt={data.title}
                    />
                    <section
                      style={{ background: "#fff" }}
                      className="checkout-area">
                      <div style={{ padding: "30px", marginTop: "-100px" }}>
                        <div className="sidebar-title">
                          <h3>Categories</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="exisitng-customer">
                              <h5>
                                {this.props.t("start_date")} :
                                <Link to={"/#"}>
                                  {moment(data.start_date).format("d MMM YYYY")}
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="exisitng-customer">
                              <h5>
                                {this.props.t("end_date")} :
                                <Link to={"/#"}>
                                  {moment(data.end_date).format("d MMM YYYY")}
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="exisitng-customer">
                              <h5>
                                Job Level :
                                <Link to={"/#"}>{data.job_level}</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="exisitng-customer">
                              <h5>
                                Job Type :{" "}
                                <Link to={"/#"}>{data.job_type}</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="coupon">
                              <h5>
                                Job Function :{" "}
                                <Link to={"/#"}>{data.job_function}</Link>
                              </h5>
                            </div>
                          </div>
                        </div>

                        {moment().format("d MMM YYYY") <
                        moment(data.end_date).format("d MMM YYYY") ? (
                          <button className="flex flex-row gap-2 rounded-full bg-red-500 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-red-600 active:bg-blue-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                            Closed
                          </button>
                        ) : (
                          <div className="flex flex-row gap-3">
                            <button className="flex  flex-row gap-2 rounded-full bg-green-500 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-blue-700">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                              Open
                            </button>

                            {isLogin ? (
                              <div className="btn-box">
                                <Link
                                  to={`/careers-details-apply/${data.slug}`}
                                  className="theme-btn btn-style-one">
                                  <span className="btn-title">Apply Now</span>
                                </Link>
                              </div>
                            ) : (
                              <div className="btn-box">
                                <Link
                                  to={`/login`}
                                  className="theme-btn btn-style-one">
                                  <span className="btn-title">
                                    Login to Apply
                                  </span>
                                </Link>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </section>

                    {/* <!-- Post Widget --> */}
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  lowongan: state.lowongan,
  member: state.member,
});

const mapDispatchToProps = { getLowongan };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CarrerDetails));
