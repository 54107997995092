import { useEffect } from "react";
import "./modalbussinescss.scss";
import xicon from "../../assets/images/xicon.png";
import { useSelector } from "react-redux";
import { html } from "../utlis/html";

const ModalBussines = ({ onRequestClose }) => {
  const { isBussinesData } = useSelector((state) => state.modal);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        onRequestClose();
      }
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="modal__backdrop2">
      <div className="modal__container2">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}>
          <h3 className="modal__title2"></h3>
          <div style={{ cursor: "pointer" }} onClick={onRequestClose}>
            <img style={{ width: "30px" }} src={xicon} alt="close" />
          </div>
        </div>

        <div
          style={{ borderRadius: "20px", cursor: "pointer" }}
          className="experience-block  ">
          <div style={{ borderRadius: "20px" }} className="inner-box">
            <div style={{ marginBottom: "5px" }} className="image">
              <img
                style={{ borderRadius: "20px", width: "100%" }}
                src={`${process.env.REACT_APP_BASE_URL}/images/bisnis/${isBussinesData.image}`}
                alt=""
              />
            </div>

            <h3>{isBussinesData.title}</h3>
            <div className="text">{html(isBussinesData.content)}</div>
          </div>
        </div>
        {/* <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder medium" />
        <div className="placeholder" /> */}
      </div>
    </div>
  );
};

export default ModalBussines;
