import React, { useEffect } from "react";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlider } from "../app/module/slider/sliderSlice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getAllSliderMobile } from "../app/module/slider/sliderMobileSlice";
// import "../../assets/css/slider.css";
const testimonialbg1 = require("./../../assets/images/background/image-9.jpg");

const settings = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const selectorSlider = (state) => state.slider;
const selectorSliderMobile = (state) => state.sliderMobile;

const HomeSlider2 = ({ t }) => {
  const dispatch = useDispatch();

  const { list } = useSelector(selectorSlider);
  const { list: listmobile } = useSelector(selectorSliderMobile);

  useEffect(() => {
    dispatch(
      getAllSlider({
        page: 1,
        size: 1,
        status: "Publish",
        type: "Home Slider",
      })
    );

    dispatch(
      getAllSliderMobile({
        page: 1,
        size: 1,
        status: "Publish",
        type: "Home Slider",
      })
    );
  }, []);

  return (
    <>
      <div className="hidden md:block">
        <Carousel
          controls="true"
          // className="slider-wrapper"
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={500}
          responsive={settings}>
          {list.map((slide, index) => {
            return (
              <div className="slider-wrapper">
                <div
                  key={index}
                  className="slide"
                  style={{
                    // maxHeight: "600px",
                    // maxWidth: "100%",
                    // backgroundSize: "",
                    background: `url('${process.env.REACT_APP_BASE_URL}/images/slider/${slide.image}')`,
                  }}>
                  {/* <div className="slide">
                <div className="inner md:mt-36">
                  <div className="auto-container"></div>
                </div>
              </div> */}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>

      <div className="block md:hidden -mt-7">
        <Carousel
          controls="true"
          // className="slider-wrapper"
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={500}
          responsive={settings}>
          {listmobile.map((slide, index) => {
            return (
              <div className="slider-wrapper">
                <div
                  key={index}
                  className="slide"
                  style={{
                    // maxHeight: "600px",
                    // maxWidth: "100%",
                    // backgroundSize: "",
                    background: `url('${process.env.REACT_APP_BASE_URL}/images/slidermobile/${slide.image}')`,
                  }}>
                  {/* <div className="slide">
                <div className="inner md:mt-36">
                  <div className="auto-container"></div>
                </div>
              </div> */}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default translate()(HomeSlider2);
