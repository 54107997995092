import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllPhoto } from "../app/module/photo/photoSlice";

class Footer extends Component {
  componentDidMount = () => {
    this.props.getAllPhoto({ page: 1, size: 1, status: "Publish" });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: photo } = this.props.photo;

    return (
      <footer className="main-footer">
        {/* <div className="auto-container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="column col-lg-4">
                  <div className="footer-widget logo-widget">
                    <div className="widget-content">
                      <div className="footer-logo">
                        <Link to="/">
                          {list.map((value) => {
                            if (value.id === "LG1" || value.id === "LG2") {
                              return (
                                <img
                                  style={{ width: "400px" }}
                                  src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                                  alt=""
                                />
                              );
                            }
                          })}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column col-lg-4">
                  <div className="footer-widget links-widget">
                    <div className="widget-content">
                      <h3>Links</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/"}>{this.props.t("home")}</Link>
                            </li>
                            <li>
                              <Link to={"/about"}>{this.props.t("about")}</Link>
                            </li>
                            <li>
                              <Link to={"/services"}>
                                {this.props.t("bisnis")}
                              </Link>
                            </li>
                            <li>
                              <Link to={"experience"}>
                                {this.props.t("experience")}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/careers"}>
                                {this.props.t("karir")}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/contact"}>
                                {this.props.t("contact")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column col-lg-4">
                  <div className="footer-widget instagram-widget">
                    <h3>Photo</h3>
                    <div className="inner-box">
                      <div className="wrapper-box row">
                        {photo.map((value) => {
                          return (
                            <div className="image">
                              <img
                                style={{
                                  objectFit: "cover",
                                  height: "50px",
                                  width: "50px",
                                }}
                                className="lazy-image owl-lazy"
                                src={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
                                alt=""
                              />

                              <div className="overlay-link">
                                <Link
                                  to={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
                                  className="lightbox-image"
                                  data-fancybox="gallery">
                                  <span className="fa fa-plus"></span>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        <div>
          <div style={{ height: 100 }} className="footer-bottom">
            <div className="copyright">
              <ul className="social-links clearfix">
                {list.map((value) => {
                  if (value.id === "FT1" || value.id === "FT2") {
                    return (
                      <li>
                        <a
                          target="_blank"
                          href={`${value.content}`}
                          rel="noreferrer">
                          <span className="fab fa-facebook"></span>
                        </a>
                      </li>
                    );
                  }

                  if (value.id === "FT13" || value.id === "FT14") {
                    return (
                      <li>
                        <a
                          target="_blank"
                          href={`${value.content}`}
                          rel="noreferrer">
                          <span className="fab fa-instagram"></span>
                        </a>
                      </li>
                    );
                  }

                  if (value.id === "FT15" || value.id === "FT16") {
                    return (
                      <li>
                        <a
                          target="_blank"
                          href={`${value.content}`}
                          rel="noreferrer">
                          <span className="fab fa-linkedin"></span>
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  photo: state.photo,
});

const mapDispatchToProps = { getAllPhoto };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Footer));
