import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLowongan } from "../app/module/lowongan/lowonganSlice";
import { getMember } from "../app/module/member/memberSlice";

import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import loginsvg from "../../assets/images/login.svg";

import parse from "html-react-parser";
import moment from "moment/moment";
import FormApply from "./form-apply";

class CarrerDetailsApply extends Component {
  constructor(props) {
    super(props);
    this.state = { slug: "" };
  }

  componentDidMount = () => {
    if (this.props.match.params) {
      this.setState({ ...this.state, slug: this.props.match.params.slug });
      this.props.getLowongan({ slug: this.props.match.params.slug });
    }
  };

  render() {
    // const { list } = this.props.referensi;
    const { data } = this.props.lowongan;
    // const { isLogin } = this.props.member;

    return (
      <>
        <HeaderBlack />

        {/* Search Popup */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        {/* Sidebar Page Container */}
        <div style={{ marginTop: "-60px", background: "#f5f5f5" }}>
          <div className="sidebar-page-container">
            <div className="auto-container">
              <div className="row">
                <div className="col-lg-12">
                  {/* News Block Two */}
                  <div className="news-block-two blog-single-post">
                    <div className="inner-box">
                      <div className="lower-content">
                        <div>
                          {/* <div className="category">{data.category}</div> */}

                          <h2>{data.title}</h2>

                          <section
                            style={{ marginTop: "-50px" }}
                            className="checkout-area">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form billing-info">
                                  <div className="shop-title-box">
                                    <h3>{this.props.t("fill_form_below")}</h3>
                                  </div>
                                  <FormApply lowongan={data} />
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  lowongan: state.lowongan,
  member: state.member,
});

const mapDispatchToProps = { getLowongan, getMember };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CarrerDetailsApply));
