import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllLowongan = createAsyncThunk("get_all_lowongan", async ({ page, size, status }, thunkAPI) => {
  return api
    .get("lowongan", {
      params: {
        pagination: {"page":page,"perPage":size},
        sort: {"field":"createdAt","order":"ASC"},
        filter: {"lang": localStorage.getItem("locale"), "status" : status},
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

export const getLowongan = createAsyncThunk("get_lowongan", async ({ slug }, thunkAPI) => {
  return api
    .get(`lowongan/${slug}`)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});



const lowongan = createSlice({
  name: "blog",
  initialState: {
    data: {
      id: "",
      title:"",
      slug: "",
      content: "",
      start_date: "",
      end_date: "",
      job_level: "",
      job_function: "",
      job_type: "",
      image: "",
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // getAllLowongan
    builder.addCase(getAllLowongan.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total_items;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllLowongan.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllLowongan.pending, (state, action) => {
      state.isFetching = true;
    });

    // getLowongan
    builder.addCase(getLowongan.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(getLowongan.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getLowongan.pending, (state, action) => {
      state.isFetching = true;
    });

  },
});

export default lowongan.reducer;
