import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getAllDivisi } from "../app/module/divisi/divisiSlice";
import { getAllHistory } from "../app/module/history/historySlice";
import { getAllKaryawan } from "../app/module/karyawan/karyawanSlice";
import { getAllVisimisi } from "../app/module/visimisi/visimisiSlice";
import Footer from "../layout/footer";

import { translate } from "react-polyglot";
import AboutSlider from "../element/about-slider";
import Sertifikat from "../element/sertifikat";
import HeaderBlack from "../layout/header-black";
import { html } from "../utlis/html";

class About extends Component {
  componentDidMount = () => {
    this.props.getAllHistory({ page: 1, size: 1, status: "Publish" });
    this.props.getAllVisimisi({ page: 1, size: 1, status: "Publish" });
    this.props.getAllKaryawan({ page: 1, size: 100, status: "Publish" });
    this.props.getAllDivisi({ page: 1, size: 1, status: "Publish" });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: history } = this.props.history;
    const { list: visimisi } = this.props.visimisi;
    const { list: karyawan } = this.props.karyawan;
    const { list: divisi } = this.props.divisi;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <HeaderBlack />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "44px", background: "#fff" }}
          className="experience-section"></section>

        <AboutSlider />
        {/* <section className="page-banner"> */}
        {/* <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div> */}
        {/* <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>About Us</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div> */}
        {/* </section> */}

        {/* <Testimonial3 /> */}

        <section className="testimonial-section -mt-10">
          <div className="auto-container">
            {list.map((value) => {
              if (value.id === "AB16" || value.id === "AB17") {
                return (
                  <div className="sec-title text-center">
                    <div className="sub-title">{value.title}</div>
                    <h2>{html(value.content)}</h2>
                  </div>
                );
              }
            })}
            <div className="row justify-content-center -mt-10">
              <div className="col-lg-12">
                <Slider {...settings} className="theme_carousel">
                  {list.map((value, id) => {
                    if (value.id === "AB18" || value.id === "AB19") {
                      return (
                        <div className="slide-item " key={id}>
                          <div className="testimonial-block-two">
                            <div className="inner-box">
                              <div className="icon">
                                <span className="fal fa-building"></span>
                              </div>
                              <div className="quote">
                                <span className="fal fa-quote-left"></span>
                              </div>
                              <h2>{value.title}</h2>
                              <div className="text">{value.content}</div>
                            </div>
                            <div className="author-info">
                              {/* <div className="thumb">
                                <img src={"item.thumb"} alt="" />
                              </div> */}
                              {/* <h4>{"item.author"}</h4> */}
                              <div className="designation">
                                PT. Dumas Lestari
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{
            marginBottom: "120px",
          }}
          className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">
                {list.map((value) => {
                  if (value.id == "VM1" || value.id == "VM2") {
                    return <>{value.title + " & "}</>;
                  }
                })}

                {list.map((value) => {
                  if (value.id == "VM3" || value.id == "VM4") {
                    return <>{value.title + " "}</>;
                  }
                })}
              </div>
              <h2>
                {list.map((value) => {
                  if (value.id == "VM1" || value.id == "VM2") {
                    return <>{value.title + " & "}</>;
                  }
                })}

                {list.map((value) => {
                  if (value.id == "VM3" || value.id == "VM4") {
                    return <>{value.title + " "}</>;
                  }
                })}
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={require("../../assets/images/icons/icon-3.png")}
                      alt=""
                    />
                  </div>
                  <h2>
                    {" "}
                    {list.map((value) => {
                      if (value.id == "VM1" || value.id == "VM2") {
                        return <>{value.title + " "}</>;
                      }
                    })}
                  </h2>
                  <div className="text">
                    {visimisi.map((value) => {
                      if (
                        value.referensi == "VM1" ||
                        value.referensi == "VM2"
                      ) {
                        return <>{html(value.content)}</>;
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={require("../../assets/images/icons/icon-21.png")}
                      alt=""
                    />
                  </div>
                  <h2>
                    {list.map((value) => {
                      if (value.id == "VM3" || value.id == "VM4") {
                        return <>{value.title + " "}</>;
                      }
                    })}
                  </h2>
                  <div className="text">
                    {visimisi.map((value) => {
                      if (
                        value.referensi == "VM3" ||
                        value.referensi == "VM4"
                      ) {
                        return <>{html(value.content)}</>;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div style={{ marginTop: "-150px" }}>
          <Sertifikat />
        </div>

        {/* History Section */}
        {/* <section className="history-section">
          <div className="auto-container">
            {list.map((value) => {
              if (value.id === "AB16" || value.id === "AB17") {
                return (
                  <div className="sec-title text-center light -mt-40">
                    <div className="sub-title">{value.title}</div>
                    <h2>{html(value.content)}</h2>
                  </div>
                );
              }
            })}

            <Tab.Container defaultActiveKey={"x"}>
              <Slider {...settings} className="theme_carousel">
                {history.map((value, index) => {
                  let key = value.slug;
                  if (index === 0) {
                    key = "x";
                  }
                  return (
                    <div className="slide-item" key={key}>
                      <Nav className="nav-tabs tab-btn-style-one">
                        <Nav.Item>
                          <Nav.Link eventKey={key}>{value.year}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  );
                })}
              </Slider>

              <Tab.Content>
                {history.map((value, index) => {
                  let key = value.slug;
                  if (index === 0) {
                    key = "x";
                  }
                  return (
                    <Tab.Pane eventKey={key} className=" fadeInUp animated">
                      <div className="row align-items-center">
                        <div className="col-lg-5">
                          <div className="image">
                            <img
                              style={{ borderRadius: "30px" }}
                              src={`${process.env.REACT_APP_BASE_URL}/images/companyhistory/${value.image}`}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="content pl-lg-4">
                            <div className="sec-title light">
                              <h2>{value.title}</h2>
                            </div>
                            <div className="text">{html(value.content)}</div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Tab.Container>
          </div>
        </section> */}

        {/* About Section Two */}
        <section className="about-section-two">
          <div className="auto-container">
            {list.map((value) => {
              if (value.id == "UM1" || value.id == "UM2") {
                let image = "";
                if (value.image) {
                  image = (
                    <div className="col-lg-5">
                      <div className="image-wrapper">
                        <div className="image-one">
                          <img
                            style={{ borderRadius: "30px" }}
                            src={`${process.env.REACT_APP_BASE_URL}/images/umum/${value.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                }

                return (
                  <div className="row align-items-center">
                    {image}
                    <div
                      className={`${value.image ? "col-lg-7" : "col-lg-12"}`}>
                      <div className="content-box">
                        <div className="sec-title text-center">
                          <div className="sub-title">
                            {this.props.t("about")}
                          </div>
                          <h2>{value.title}</h2>
                          <div className="text-2xl mt-3 leading-1">
                            {html(value.content)}
                          </div>
                        </div>
                        {/* <div className="author-info">
                          <div className="wrapper-box">
                            <h2>Rosalina D. William</h2>
                            <div className="designation">Founder</div>
                            <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod..</div>
                          </div>
                          <div className="signature">
                            <img src={require("../../assets/images/resource/signature.png")} alt="" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </section>

        {/* <section className="about-section-two">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="content-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms">
                  <div className="sec-title">
                    <div className="sub-title">About Us</div>
                    <h2>
                      We Work With <br />
                      You To Address
                    </h2>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </div>
                  </div>
                  <div className="author-info">
                    <div className="wrapper-box">
                      <h2>Rosalina D. William</h2>
                      <div className="designation">Founder</div>
                    </div>
                    <div className="signature">
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="image-wrapper wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms">
                  <div className="image-one">
                    <img
                      style={{ borderRadius: "30px" }}
                      src={require("../../assets/images/resource/image-10.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!-- Why Choose Us Section --> */}

        {/* Team Section */}
        {/* <section style={{ marginTop: "-130px" }} className="team-section">
          <div className="auto-container">
            {list.map((value) => {
              if (value.id == "AB12" || value.id == "AB13") {
                return (
                  <>
                    <div className="sec-title text-center">
                      <div className="sub-title">{value.title}</div>
                      <h2>{html(value.content)}</h2>
                    </div>
                  </>
                );
              }
            })}

            <div className="row">
              {karyawan.map((value) => {
                return (
                  <>
                    <div
                      className="col-lg-3 team-block-one wow fadeInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1200ms">
                      <div className="inner-box">
                        <div className="image">
                          <img
                            style={{ borderRadius: "30px" }}
                            src={`${process.env.REACT_APP_BASE_URL}/images/karyawan/${value.image}`}
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <div className="designation">
                            {value?.divisi_detail?.title}
                          </div>
                          <h3>{value.nama}</h3>
                          <div className="text">{html(value.prakata)}</div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section> */}

        {/* Services Section Four */}
        {/* <section className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">{this.props.t("divisi")}</div>
              <h2>{this.props.t("divisi")}</h2>
            </div>
            <div className="row">
              {divisi.map((value) => {
                return (
                  <div className="col-lg-6">
                    <div className="icon-box">
                      <div className="icon">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/images/divisi/${value.image}`}
                          alt=""
                        />
                      </div>
                      <h2>{value.title}</h2>
                      <div className="text">{value.content}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section> */}

        {/* Sponsors Section */}

        {/* <Brand1 /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  referensi: state.referensi,
  visimisi: state.visimisi,
  karyawan: state.karyawan,
  divisi: state.divisi,
});

const mapDispatchToProps = {
  getAllHistory,
  getAllVisimisi,
  getAllKaryawan,
  getAllDivisi,
};
export default connect(mapStateToProps, mapDispatchToProps)(translate()(About));
