import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllPhotoCategory = createAsyncThunk("get_all_photo_category", async ({ page, size, status }, thunkAPI) => {
  return api
    .get("photocategory", {
      params: {
        pagination: { "page": page, "perPage": size },
        sort: { "field": "createdAt", "order": "DESC" },
        filter: {
          "lang": localStorage.getItem("locale"),
          "status": status
        },
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

const photoCategory = createSlice({
  name: "photoCategory",
  initialState: {
    data: {
      id: "",
      title: "",
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // getAllPhotoCategory
    builder.addCase(getAllPhotoCategory.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total_items;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllPhotoCategory.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllPhotoCategory.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

export default photoCategory.reducer;
