import React from "react";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { memberLogin, clearState } from "../app/module/member/memberSlice";
import { BeatLoader } from "react-spinners";

function LoginForm({ t }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const { isFetching, isSuccess, isError, errorMessage } = useSelector(
    (state) => state.member
  );
  
  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      history.push("/login-success");
    }
  }, [isSuccess]);

  const doLogin = (data) => {
    dispatch(
      memberLogin({
        email: data.email,
        password: data.password,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(doLogin)}>
      <div className="row">
        <div className="col-md-12">
          <div className="field-label">
            {isError && <span style={{ color: "red" }}>{errorMessage}</span>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="field-label">{t("email")} *</div>
          <div className="field-input">
            <input
              type="email"
              name="email"
              placeholder={t("email")}
              {...register("email")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "25px" }} className="col-md-12">
          <div className="field-label">{t("password")} *</div>
          <div className="field-input">
            <input
              type="password"
              name="password"
              placeholder={t("password")}
              {...register("password")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.password && (
              <span style={{ color: "red" }}>{errors.password.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "35px" }} className="col-md-12">
          <button type="submit" className="theme-btn btn-style-one">
            <span style={{ color: "white" }} className="btn-title">
              {isSuccess ? t("success") : t("masuk")}
              {isFetching && <BeatLoader color="#fff" />}
            </span>
          </button>
        </div>
        <div style={{ marginTop: "10px" }} className="col-md-12">
          <a
          // onClick={() => this.langSwitcher("en")}
          >
            <span className="btn-title">
              {t("dont_have_account")}{" "}
              <Link to={"/register"}>{t("daftar")}</Link>
            </span>
          </a>
        </div>
      </div>
    </form>
  );
}

export default translate()(LoginForm);
