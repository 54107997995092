import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllBussinesCategory = createAsyncThunk(
  "get_all_bussines_category",
  async ({ page, size, status, bisnis }, thunkAPI) => {
    return api
      .get("bussinescategory", {
        params: {
          pagination: { page: page, perPage: size },
          sort: { field: "createdAt", order: "ASC" },
          filter: {
            lang: localStorage.getItem("locale"),
            status: status,
            bisnis: bisnis
          },
        },
      })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          return thunkAPI.rejectWithValue(err.response.data.message);
        }
      );
  }
);

const bussinescategory = createSlice({
  name: "bussinescategory",
  initialState: {
    data: {
      id: "",
      title: "",
      slug: "",
      category: "",
      type: "",
      content: "",
      image: "",
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    page: 1,
    size: 12,
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllBussinesCategory
    builder.addCase(getAllBussinesCategory.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllBussinesCategory.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllBussinesCategory.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

export const { setPage } = bussinescategory.actions;
export default bussinescategory.reducer;
