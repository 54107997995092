import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import loginsvg from "../../assets/images/login.svg";
import LoginForm from "./LoginForm";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Register extends Component {
  render() {
    return (
      <>
        <HeaderBlack />
        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        <div
          style={{
            marginTop: "-50px",
            marginBottom: "-60px",
            // background: "#f5f5f5",
          }}>
          <div className="auto-container">
            <section className="cases-section">
              <div className="case-tabs">
                <div className="container-fluid">
                  <div className="sec-title text-center">
                    <div className="sub-title">{this.props.t("karir")}</div>
                    <h2> {this.props.t("daftar")} </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* <!--Start Checkout area--> */}
        <section style={{marginBottom: "50px"}} className="checkout-area">
          <div className="auto-container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="form billing-info">
                  <div className="shop-title-box">
                    <h3>{this.props.t("fill_form_below")}</h3>
                  </div>
                  <LoginForm />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <img src={loginsvg} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </section>
        {/* <!--End Checkout area--> */}

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  referensi: state.referensi,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Register));
