import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllBlog = createAsyncThunk("get_all_blog", async ({ page, size, status, type, cari }, thunkAPI) => {
  let filter = {
    lang: localStorage.getItem("locale"),
    type: type,
    status: status,
  }

  if (cari !== '' || cari !== undefined) {
    Object.assign(filter, { title: cari })
  }

  return api
    .get("projects", {
      params: {
        pagination: { "page": page, "perPage": size },
        sort: { "field": "createdAt", "order": "DESC" },
        filter: filter,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

export const getAllBlogType = createAsyncThunk("get_all_blog_type", async ({ page, size, status, type }, thunkAPI) => {
  let filter = {
    lang: localStorage.getItem("locale"),
    type: type,
    status: "Publish"
  }

  return api
    .get("projects", {
      params: {
        pagination: { "page": page, "perPage": size },
        sort: { "field": "createdAt", "order": "ASC" },
        filter: filter
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

export const getBlog = createAsyncThunk("get_blog", async ({ slug }, thunkAPI) => {
  return api
    .get(`projects/${slug}`)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});


const blog = createSlice({
  name: "projects",
  initialState: {
    data: {
      id: "",
      title: "",
      slug: "",
      content: "",
      category: "",
      type: "",
      image: [],
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    list_type: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    page: 1,
    size: 9,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAllBlog
    builder.addCase(getAllBlog.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllBlog.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllBlog.pending, (state, action) => {
      state.isFetching = true;
    });

    // getAllBlogType
    builder.addCase(getAllBlogType.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.list_type = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total_items;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllBlogType.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllBlogType.pending, (state, action) => {
      state.isFetching = true;
    });

    // getAllBlogType
    builder.addCase(getBlog.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(getBlog.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getBlog.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

export const { setPage } = blog.actions
export default blog.reducer;
