import parse from "html-react-parser";
import moment from "moment";
import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getAllBlog } from "../app/module/blog/blogSlice";
import HeaderNews from "../element/HeaderNews";
import PaginatedItems from "../element/paginate";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import { html } from "../utlis/html";

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cari: "",
      loading: false,
      type: "",
    };
  }

  componentDidMount = () => {
    const { page, size } = this.props.blog;

    this.props.getAllBlog({
      page: page,
      size: size,
      status: "Publish",
    });
  };

  onSearch = () => {
    const { page, size } = this.props.blog;
    if (this.state.cari !== "") {
      if (this.state.type === "Semua") {
        setTimeout(
          this.props.getAllBlog({
            page: page,
            size: size,
            status: "Publish",
            cari: this.state.cari,
          }),
          2000
        );
      } else {
        setTimeout(
          this.props.getAllBlog({
            page: page,
            size: size,
            status: "Publish",
            type: this.state.type,
            cari: this.state.cari,
          }),
          2000
        );
      }
    }
  };

  onChangeType = (event) => {
    const { page, size } = this.props.blog;

    this.setState({
      ...this.state,
      type: event.target.value,
    });

    if (event.target.value === "Semua") {
      this.props.getAllBlog({
        page: page,
        size: size,
        status: "Publish",
        cari: this.state.cari,
      });
    } else {
      this.props.getAllBlog({
        page: page,
        size: size,
        status: "Publish",
        type: event.target.value,
        cari: this.state.cari,
      });
    }
  };

  render() {
    const { list } = this.props.referensi;
    const { list: blog, isSuccess, isFetching } = this.props.blog;

    return (
      <>
        <HeaderBlack />

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section">
          {/* <div className="auto-container">
                    <div className="sec-title text-center">
                    <div className="sub-title">{this.props.t("bisnis")}</div>
                    <h2>{this.props.t("bisnis")}</h2>
                    </div>
                </div> */}
        </section>
        {/* <!--End Banner Section --> */}

        <HeaderNews />

        {/* <!-- News Section --> */}
        <section
          style={{
            background: "#f6f8fc",
          }}
          className="news-section w-full">
          <div className="auto-container w-full">
            <div className="-mt-20 w-full flex flex-col md:flex-row">
              <div className="showing-result-shorting mr-5 w-40 ">
                <div className="shorting">
                  <select
                    className="selectmenu"
                    onChange={(e) => this.onChangeType(e)}
                    value={this.state.selectedType}>
                    <option value={`Semua`}>{`Semua`}</option>
                    <option
                      value={`Selesai Pengerjaan`}>{`Selesai Pengerjaan`}</option>
                    <option
                      value={`Dalam Pengerjaan`}>{`Dalam Pengerjaan`}</option>
                  </select>
                </div>
              </div>
              <div
                style={{ border: "2px solid #ebebeb" }}
                className="-mt-20 md:-mt-0 w-80 p-1 relative flex items-center w-full h-14 rounded-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>

                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                  type="text"
                  id="search"
                  placeholder="Search something.."
                  value={this.state.cari}
                  onChange={(e) =>
                    this.setState({ ...this.state, cari: e.target.value })
                  }
                />
                <div
                  onClick={this.onSearch}
                  className="cursor-pointer bg-gray-100 hover:bg-gray-200 text-gray-600 font-bold py-2 px-4 rounded inline-flex items-center">
                  {isFetching ? (
                    <BeatLoader color="#444444" className="mr-2" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  )}

                  <span>Cari</span>
                </div>
              </div>
            </div>

            {/* <div style={{ marginBottom: "50px" }} className="contact-form">
              <div className="row">
                <div className="col-md-10">
                  <div
                    style={{
                      marginRight: "-10px",
                      marginLeft: "-10px",
                      marginTop: "10px",
                    }}
                    className="form-group">
                    <input
                      style={{ borderRadius: "50px" }}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Search"
                      required=""
                      value={this.state.cari}
                      onChange={(e) =>
                        this.setState({ ...this.state, cari: e.target.value })
                      }
                    />
                    <i
                      style={{ marginTop: "-40px" }}
                      className="icon far fa-search"></i>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    style={{ marginTop: "10px" }}
                    className="theme-btn btn-style-one"
                    type="button"
                    onClick={this.onSearch}>
                    <span className="btn-title">
                      {isFetching ? (
                        <BeatLoader color="#fff" />
                      ) : (
                        this.props.t("search")
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div> */}

            <div className="row mt-10 md:-mt-10">
              {blog.map((value) => {
                return (
                  <>
                    <div
                      key={`news-${value.slug}`}
                      className="news-block-one col-lg-3">
                      <div className="inner-box">
                        <div className="image">
                          <Link to={`/project-details/${value.slug}`}>
                            {value?.image.map((img, index) => {
                              if (index === 0) {
                                return (
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      height: "300px",
                                    }}
                                    src={`${process.env.REACT_APP_BASE_URL}/images/project/${img.image}`}
                                    alt=""
                                  />
                                );
                              }
                            })}
                          </Link>
                        </div>
                        <div className="lower-content">
                          <div className="category flex flex-col items-center justify-items-center">
                            {/* {value.category} */}
                            <span className="text-xl">
                              {moment(value.createdAt).format("DD")}
                            </span>
                            <span className="text-xs">
                              {moment(value.createdAt).format("MMM YYYY")}
                            </span>
                          </div>

                          <h3
                            className="-mt-5"
                            style={{ height: "70px", fontSize: "20px" }}>
                            <Link to={`/project-details/${value.slug}`}>
                              {value.title.substring(0, 50)}
                            </Link>
                          </h3>

                          {html(value.content.substring(0, 100))}

                          <ul className="post-meta">
                            <li>
                              <Link to={`/project-details/${value.slug}`}>
                                <i className="far fa-clock"></i>
                                Project {value.type}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/project-details/${value.slug}`}>
                                <i className="far fa-user"></i>By Admin
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <PaginatedItems itemsPerPage={10} />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  blog: state.blog,
});

const mapDispatchToProps = { getAllBlog };
export default connect(mapStateToProps, mapDispatchToProps)(translate()(News));
