import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const createMail = createAsyncThunk("create_mail", async ({ data }, thunkAPI) => {
  return api
    .post("mail", data)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

const mail = createSlice({
  name: "mail",
  initialState: {
    data: {
      id: "",
      name: "",
      email: "",
      content: "",
      status: "",
      type: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // createMail
    builder.addCase(createMail.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(createMail.rejected, (state, payload) => {
      state.isFetching = true;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(createMail.pending, (state, action) => {
      state.isFetching = false;
    });
  },
});

export default mail.reducer;
