import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { toggle } from "../app/module/modal/modalSlice";
import { getAllPhoto } from "../app/module/photo/photoSlice";

class CaseStudy extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }

  componentDidMount = () => {
    this.props.getAllPhoto({ page: 1, size: 1, status: "Publish" });
  };

  toggleModal = () => {
    this.props.toggle();
  };

  render() {
    const { list } = this.props.referensi;
    const { list: photo } = this.props.photo;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <Slider {...settings}>
          {photo.map((value, id) => (
            <div onClick={this.toggleModal} className="slide-item" key={id}>
              <div className="single-product-item">
                <div className="img-holder">
                  <img
                    style={{
                      objectFit: "cover",
                      height: "300px",
                    }}
                    src={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
                    alt="Awesome Product"
                  />
                  <div className="overlay-btn"></div>
                </div>
                <div style={{ padding: "10px" }} className="title-holder">
                  <div className="static-content">
                    <h3 className="title">{value.title}</h3>
                  </div>
                </div>
              </div>
            </div>
            // <div
            //   key={"photo" + id}
            //   style={{ cursor: "pointer" }}
            //   className="case-block-one">
            //   <div className="inner-box">
            //     <div className="image">
            //       <img
            //         style={{ borderRadius: "30px" }}
            //         src={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
            //         alt=""
            //       />
            //     </div>
            //     <div
            //       style={{
            //         borderRadius: "10px",
            //         padding: "5px",
            //         marginBottom: "-20px",
            //       }}
            //       className="overlay">
            //       <div>
            //         <h5>{value.title}</h5>
            //         <div className="view-project">
            //           <Link
            //             data-fancybox="example gallery"
            //             to="assets/images/gallery/gallery-10.jpg"
            //             className="zoom-btn">
            //             <span>+</span>
            //           </Link>
            //         </div>

            //       </div>
            //     </div>
            //   </div>
            // </div>
          ))}
        </Slider>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  photo: state.photo,
});

const mapDispatchToProps = { getAllPhoto, toggle };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CaseStudy));
