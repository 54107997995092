import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const uploadCV = createAsyncThunk("uploadCV", async (data, thunkAPI) => {
  return api
    .post("pelamar", data)
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});


const pelamar = createSlice({
  name: "pelamar",
  initialState: {
    data: {
      id: "",
      member: "",
      lowongan: "",
      image: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLogin: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // uploadCV
    builder.addCase(uploadCV.fulfilled, (state, { payload }) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload.data;
      return state;
    });
    builder.addCase(uploadCV.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(uploadCV.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

export const { clearState, logout } = pelamar.actions
export default pelamar.reducer;
