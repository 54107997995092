import jwt_decode from "jwt-decode";
import React, { useEffect } from "react";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useCallback, useState } from "react";
import { BeatLoader } from "react-spinners";
import { getMember } from "../app/module/member/memberSlice";
import { clearState, uploadCV } from "../app/module/pelamar/pelamarSlice";

function FormApply({ t, lowongan }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState({ image: "" });

  const { isSuccess, isError, errorMessage, data } = useSelector(
    (state) => state.member
  );

  const {
    isFetching,
    isSuccess: isSuccessPelamar,
    isError: isErrorPelamar,
    errorMessage: errorMessagePelamar,
  } = useSelector((state) => state.pelamar);

  const getMemberOne = useCallback(async () => {
    const tokenJSON = JSON.parse(localStorage.getItem("token"));
    if (tokenJSON) {
      const token = tokenJSON.access.token;
      const decodedToken = jwt_decode(token);
      dispatch(getMember(decodedToken.sub));
    }
  }, []); // every time id changed, new book will be loaded

  useEffect(() => {
    getMemberOne();

    if (isSuccessPelamar) {
      dispatch(clearState({}));
      history.push("/apply-success");
    }
  }, [isSuccessPelamar]);

  const doUpload = (data) => {
    let form_data = new FormData();

    const tokenJSON = JSON.parse(localStorage.getItem("token"));
    if (tokenJSON) {
      if (selectedFile.image !== "" && selectedFile.image !== null) {
        const token = tokenJSON.access.token;
        const decodedToken = jwt_decode(token);

        form_data.append("image", selectedFile?.image);
        form_data.append("lowongan", lowongan.id);
        form_data.append("member", decodedToken.sub);
        dispatch(uploadCV(form_data));
      }
    }
  };

  const onFileChange = (event) => {
    // Update the state
    setSelectedFile({ image: event.target.files[0] });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="field-label">
            {isError && <span style={{ color: "red" }}>{errorMessage}</span>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="field-label">daftar dengan akun *</div>
          {/* <div className="field-input">
            <input
              type="email"
              name="email"
              placeholder={t("email")}
              defaultValue={data.email}
              style={{ marginBottom: "-2px" }}
              disabled
            />
          </div> */}
          {data.email}
        </div>
        <div style={{ marginTop: "25px" }} className="col-md-12">
          <div className="field-label">Upload CV *(format .pdf)</div>
          <div className="field-input">
            <input type="file" onChange={onFileChange} />
          </div>
          <div style={{ color: "red" }} className="field-label">
            {isErrorPelamar && errorMessagePelamar} *
          </div>
        </div>
        <div style={{ marginTop: "35px" }} className="col-md-12">
          <button onClick={doUpload} className="theme-btn btn-style-one">
            <span style={{ color: "white" }} className="btn-title">
              {isSuccessPelamar ? t("success") : "Apply Now"}
              {isFetching && <BeatLoader color="#fff" />}
            </span>
          </button>
        </div>
        <div style={{ marginTop: "10px" }} className="col-md-12">
          <a
          // onClick={() => this.langSwitcher("en")}
          >
            <span className="btn-title">
              {t("dont_have_account")}{" "}
              <Link to={"/register"}>{t("daftar")}</Link>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default translate()(FormApply);
