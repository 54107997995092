import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "./menu";
import { logout } from "../app/module/member/memberSlice";
import iconlogin from "../../assets/images/iconlogin.png";

class Header extends Component {
  state = {
    scrolled: false,
    lang: localStorage.getItem("locale") || "id",
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ ...this.state, scrolled: true });
    } else {
      this.setState({ ...this.state, scrolled: false });
    }
  };

  langSwitcher = (lang) => {
    localStorage.setItem("locale", lang);
    this.setState({
      ...this.state,
      lang: lang,
    });

    window.location.reload();
  };

  doLogout = () => {
    this.props.logout();
    window.location.reload();
  };

  render() {
    const { scrolled } = this.state;
    const { list } = this.props.referensi;
    const { isLogin } = this.props.member;

    return (
      <>
        <header
          // className={"main-header"}>
          className={scrolled ? "main-header fixed-header" : "main-header"}>
          <div className="header-top">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to={"/"}>
                        {list.map((value) => {
                          if (value.id === "LG1" || value.id === "LG2") {
                            return (
                              <img
                                className="w-64 md:w-80"
                                src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                                alt=""
                              />
                            );
                          }
                        })}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="top-middile">
                  <div className="contact-info">
                    {list.map((value) => {
                      if (value.id === "FT5" || value.id === "FT6") {
                        return (
                          <div className="single-info">
                            <div className="icon-box">
                              <i className="flaticon-call-1"></i>
                            </div>
                            <div className="text">{this.props.t("nohp")}</div>

                            <h4>
                              <Link>{value.content}</Link>
                            </h4>
                          </div>
                        );
                      }

                      if (value.id === "FT3" || value.id === "FT4") {
                        return (
                          <div className="single-info">
                            <div className="icon-box">
                              <i className="flaticon-email-4"></i>
                            </div>
                            <div className="text">{this.props.t("email")}</div>
                            <h4>
                              <Link>{value.content}</Link>
                            </h4>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <div className="top-right">
                  {this.state.lang === "id" ? (
                    <button
                      style={{
                        border: "2px solid #bcbcbc",
                      }}
                      onClick={() => this.langSwitcher("en")}
                      className="rounded-full hover:bg-white hover:bg-white/30 px-3 py-2 font-bold">
                      English
                    </button>
                  ) : (
                    <button
                      style={{
                        border: "2px solid #bcbcbc",
                      }}
                      onClick={() => this.langSwitcher("id")}
                      className="rounded-full hover:bg-white hover:bg-white/30 px-3 py-2 font-bold">
                      Indonesia
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars py-3"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>

                  <ul className="social-links clearfix">
                    <li>
                      {isLogin ? (
                        <a className="py-4" onClick={this.doLogout}>
                          <span style={{ fontWeight: 600, color: "white" }}>
                            <img
                              style={{
                                width: "25px",
                                marginRight: "10px",
                                transform: "rotate(180deg)",
                              }}
                              src={iconlogin}
                            />
                            Keluar
                          </span>
                        </a>
                      ) : (
                        <Link to="/register">
                          <span
                            className="flex flex-row items-center"
                            style={{ color: "white" }}>
                            <img
                              style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "10px",
                              }}
                              src={iconlogin}
                              alt=""
                            />
                            <div className="flex flex-col">
                              <span style={{ fontWeight: 600, color: "white" }}>
                                {this.props.t("daftar")}
                              </span>
                              <span className="text-xs">
                                {this.props.t("to_apply")}
                              </span>
                            </div>
                          </span>
                        </Link>
                      )}
                    </li>
                    {list.map((value) => {
                      if (value.id === "FT1" || value.id === "FT2") {
                        return (
                          <li className="py-2">
                            <a
                              target="_blank"
                              href={`${value.content}`}
                              rel="noreferrer">
                              <span className="fab fa-facebook"></span>
                            </a>
                          </li>
                        );
                      }

                      if (value.id === "FT13" || value.id === "FT14") {
                        return (
                          <li className="py-2">
                            <a
                              target="_blank"
                              href={`${value.content}`}
                              rel="noreferrer">
                              <span className="fab fa-instagram"></span>
                            </a>
                          </li>
                        );
                      }

                      if (value.id === "FT15" || value.id === "FT16") {
                        return (
                          <li className="py-2">
                            <a
                              target="_blank"
                              href={`${value.content}`}
                              rel="noreferrer">
                              <span className="fab fa-linkedin"></span>
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }>
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to={"/"} title="">
                  {list.map((value) => {
                    if (value.id === "LG1" || value.id === "LG2") {
                      return (
                        <img
                          style={{ width: "160px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                          alt=""
                        />
                      );
                    }
                  })}
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to={"/"}>
                  {list.map((value) => {
                    if (value.id === "LG1" || value.id === "LG2") {
                      return (
                        <img
                          style={{ width: "180px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                          alt=""
                        />
                      );
                    }
                  })}
                </Link>
              </div>
              <div className="menu-outer">
                <Menu />
              </div>
              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-twitter"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-facebook-square"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-pinterest-p"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-instagram"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-youtube"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  member: state.member,
});

// export default translate()(Header);
export default connect(mapStateToProps, { logout })(translate()(Header));
