import React, { Component } from "react";
import { Link } from "react-router-dom";

import { translate } from "react-polyglot";
import { connect } from "react-redux";
import PortfolioFilter1 from "./../element/portfolio-filter1";

import { getAllBlog } from "../app/module/blog/blogSlice";
import { getAllKaryawan } from "../app/module/karyawan/karyawanSlice";
import { getAllStatistik } from "../app/module/statistik/statistikSlice";
import { getAllVisimisi } from "../app/module/visimisi/visimisiSlice";

import HomeSlider2 from "../element/home-slider2";
import Sertifikat from "../element/sertifikat";
import HeaderBlack from "../layout/header-black";
import { html } from "../utlis/html";

const teambg1 = require("./../../assets/images/background/image-3.jpg");
const newsbg1 = require("./../../assets/images/background/image-4.jpg");
const touchbg1 = require("./../../assets/images/background/image-8.jpg");

class Index extends Component {
  componentDidMount = () => {
    this.props.getAllVisimisi({ page: 1, size: 1, status: "Publish" });
    this.props.getAllStatistik({ page: 1, size: 1, status: "Publish" });
    this.props.getAllKaryawan({ page: 1, size: 4, status: "Publish" });
    this.props.getAllBlog({
      page: 1,
      size: 4,
      status: "Publish",
      // type: "News",
    });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: statistik } = this.props.statistik;
    const { list: karyawan } = this.props.karyawan;
    const { list: blog } = this.props.blog;
    const { list: visimisi } = this.props.visimisi;

    return (
      <>
        <HeaderBlack />

        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "44px", background: "#fff" }}
          className="experience-section"></section>

        {/* <HomeSlider1 /> */}
        <HomeSlider2 />

        {/* <!-- Welcome Setion --> */}
        {/* <section className="welcome-section pt-0">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="row m-0">
                {list.map((value) => {
                  if (value.id === "AB3" || value.id === "AB4") {
                    return (
                      <div
                        className="welcome-block-one col-lg-4 wow fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1200ms">
                        <div
                          className="inner-box"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_BASE_URL +
                              "/images/about/" +
                              value.image +
                              ")",
                          }}>
                          <div className="icon-box">
                            <span className="flaticon-analysis"></span>
                          </div>
                          <h2>{value.title}</h2>
                          <div className="text">{html(value.content)}</div>
                        
                        </div>
                      </div>
                    );
                  }

                  if (value.id === "AB5" || value.id === "AB6") {
                    return (
                      <div
                        className="welcome-block-one col-lg-4 wow fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1200ms">
                        <div
                          className="inner-box"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_BASE_URL +
                              "/images/about/" +
                              value.image +
                              ")",
                          }}>
                          <div className="icon-box">
                            <span className="flaticon-tax"></span>
                          </div>
                          <h2>{value.title}</h2>
                          <div className="text">{html(value.content)}</div>
                         
                        </div>
                      </div>
                    );
                  }

                  if (value.id === "AB7" || value.id === "AB8") {
                    return (
                      <div
                        className="welcome-block-one col-lg-4 wow fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1200ms">
                        <div
                          className="inner-box"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_BASE_URL +
                              "/images/about/" +
                              value.image +
                              ")",
                          }}>
                          <div className="icon-box">
                            <span className="flaticon-work-1"></span>
                          </div>
                          <h2>{value.title}</h2>
                          <div className="text">{html(value.content)}</div>
                          {/* <div className="read-more">
                            <Link to={"/#"}>Read More</Link>
                          </div> 
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section> */}

        {/* <!-- About Section --> */}
        <section className="about-section">
          <div className="auto-container">
            <div className="row align-items-center">
              {list.map((value) => {
                if (value.id === "AB1" || value.id === "AB2") {
                  let image = "";
                  if (value.image) {
                    image = (
                      <div className="col-lg-5">
                        <div className="image-wrapper">
                          <div
                            className="image-two wow fadeInUp"
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms">
                            <div className="image-outer">
                              <img
                                style={{ borderRadius: "30px" }}
                                src={`${process.env.REACT_APP_BASE_URL}/images/about/${value.image}`}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <>
                      {image}
                      <div
                        className={`${value.image ? "col-lg-7" : "col-lg-12"}`}>
                        <section
                          style={{ background: "white", padding: "10px" }}
                          className="services-section-four">
                          <div className="sec-title text-center">
                            <div className="sub-title">
                              {this.props.t("about")}
                            </div>
                            <h3 className="text-4xl font-bold">
                              {value.title}
                            </h3>
                            <div className="text-3xl mt-3 leading-1">
                              {html(value.content)}
                            </div>
                          </div>

                          {/* {list.map((value) => {
                            if (value.id === "FT5" || value.id === "FT6") {
                              return (
                                <div
                                  style={{
                                    marginBottom: "20px",
                                    marginLeft: "-20px",
                                  }}
                                  className="col-lg-12">
                                  <div className="icon-box">
                                    <div
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                      }}
                                      className="icon">
                                      <img
                                        style={{
                                          marginTop: "-20px",
                                          padding: "10px",
                                          width: "60px",
                                          height: "60px",
                                        }}
                                        src={require("../../assets/images/icons/icon-1.png")}
                                        alt=""
                                      />
                                    </div>
                                    <div style={{ marginLeft: "-20px" }}>
                                      <h4 style={{ fontSize: "15px" }}>
                                        {this.props.t("nohp")}
                                      </h4>
                                      <h2 style={{ fontSize: "20px" }}>
                                        {value.content}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            if (value.id === "FT3" || value.id === "FT4") {
                              return (
                                <div
                                  style={{
                                    marginBottom: "20px",
                                    marginLeft: "-20px",
                                  }}
                                  className="col-lg-4">
                                  <div className="icon-box">
                                    <div
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                      }}
                                      className="icon">
                                      <img
                                        style={{
                                          marginTop: "-20px",
                                          padding: "10px",
                                          width: "60px",
                                          height: "60px",
                                        }}
                                        src={require("../../assets/images/icons/icon-2.png")}
                                        alt=""
                                      />
                                    </div>
                                    <div style={{ marginLeft: "-20px" }}>
                                      <h4 style={{ fontSize: "15px" }}>
                                        {this.props.t("email")}
                                      </h4>
                                      <h2 style={{ fontSize: "20px" }}>
                                        {value.content}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })} */}
                        </section>
                        {/* <div className="content-box">
                          <div className="sec-title">
                            <div className="sub-title">
                              {this.props.t("about")}
                            </div>
                            <h2>{value.title}</h2>
                            <div className="text">{html(value.content)}</div>
                          </div>
                          <div className="row">
                            {list.map((value) => {
                              if (value.id === "FT5" || value.id === "FT6") {
                                return (
                                  <div className="info-column col-md-6">
                                    <div className="icon-box">
                                      <div className="icon">
                                        <img
                                          src={require("../../assets/images/icons/icon-1.png")}
                                          alt=""
                                        />
                                      </div>
                                      <h5>{this.props.t("nohp")}</h5>
                                      <h2
                                        style={{
                                          fontSize: "30px",
                                          display: "inline-block",
                                          width: "200px",
                                          overflowWrap: "break-word",
                                        }}>
                                        {value.content}{" "}
                                      </h2>
                                    </div>
                                  </div>
                                );
                              }

                              if (value.id === "FT3" || value.id === "FT4") {
                                return (
                                  <div className="info-column col-md-6">
                                    <div className="icon-box">
                                      <div className="icon">
                                        <img
                                          src={require("../../assets/images/icons/icon-2.png")}
                                          alt=""
                                        />
                                      </div>
                                      <h5>{this.props.t("email")}</h5>
                                      <h2
                                        style={{
                                          fontSize: "30px",
                                          display: "inline-block",
                                          width: "200px",
                                          overflowWrap: "break-word",
                                        }}>
                                        {value.content}
                                      </h2>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div> */}
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </section>

        {/* <section className="services-section-two">
          <div className="auto-container">
            <div className="wrapper-box">
              <div
                className="left-column"
                style={{ backgroundImage: "url(" + touchbg1 + ")" }}>
                <div className="sec-title light">
                  <div className="sub-title">{this.props.t("contact")}</div>
                  <h2>{this.props.t("help")}</h2>
                </div>
                <ContactForm />
              </div>
              <div className="right-column">
                <div className="services-content">
                  {list.map((value) => {
                    if (value.id === "ST1" || value.id === "ST2") {
                      return (
                        <>
                          <div className="sec-title">
                            <div className="sub-title">
                              {this.props.t("statistik")}
                            </div>
                            <h2>{value.title}</h2>
                          </div>
                        </>
                      );
                    }
                  })}

                  {statistik.map((value) => {
                    return (
                      <>
                        <div
                          className="icon-box wow fadeInUp"
                          data-wow-delay="200ms"
                          data-wow-duration="1200ms">
                          <div style={{ marginTop: "-10px" }} className="icon">
                            <img
                              style={{ width: "60px" }}
                              src={`${process.env.REACT_APP_BASE_URL}/images/statistik/${value.image}`}
                              alt=""
                            />
                          </div>
                          <div className="count-outer count-box">
                            <CountUp end={value.counter} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <span
                                    style={{
                                      fontSize: "40px",
                                      fontWeight: "800",
                                    }}
                                    className="count-text"
                                    ref={countUpRef}
                                  />
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </div>
                          <div className="text">{value.title}</div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              {list.map((value) => {
                if (value.id === "AB12" || value.id === "AB13") {
                  return (
                    <>
                      <div className="sub-title">{value.title}</div>
                      <h2>{html(value.content)}</h2>
                    </>
                  );
                }
              })}
            </div>
            <div className="row">
              {karyawan.map((value) => {
                return (
                  <>
                    <div
                      className="col-lg-3 team-block-one wow fadeInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1200ms">
                      <div className="inner-box">
                        <div className="image">
                          <img
                            style={{
                              objectFit: "cover",
                              height: "420px",
                              borderRadius: "30px",
                            }}
                            src={`${process.env.REACT_APP_BASE_URL}/images/karyawan/${value.image}`}
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <div className="designation">
                            {value?.divisi_detail?.title}
                          </div>
                          <h3>{value.nama}</h3>
                          <div className="text">{html(value.prakata)}</div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section> */}

        {/* <!-- Team Section --> */}
        {/* {list.map((value) => {
          if (value.id === "AB12" || value.id === "AB13") {
            return (
              <>
                <section className="team-section">
                  <div className="auto-container">
                    <div
                      className="sec-bg"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/images/about/${value.image})`,
                      }}></div>
                    <div className="sec-title text-center">
                      <div className="sub-title">{value.title} </div>
                      <h2>{html(value.content)}</h2>
                    </div>

                    <div className="row">
                      {karyawan.map((value) => {
                        return (
                          <>
                            <div
                              className="col-lg-4 team-block-one wow fadeInUp"
                              data-wow-delay="200ms"
                              data-wow-duration="1200ms">
                              <div className="inner-box">
                                <div className="image">
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      height: "420px",
                                      borderRadius: "30px",
                                    }}
                                    src={`${process.env.REACT_APP_BASE_URL}/images/karyawan/${value.image}`}
                                    alt=""
                                  />
                                </div>
                                <div className="content">
                                  <div className="designation">
                                    {value?.divisi_detail?.title}
                                  </div>
                                  <h3>{value.nama}</h3>
                                  <div className="text">
                                    {html(value.prakata)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </section>
              </>
            );
          }
        })} */}

        {/* <!-- Gallery Section --> */}

        {/* <!-- News Section --> */}

        <div>
          <Sertifikat />
        </div>

        {/* {list.map((value) => {
          if (value.id === "AB14" || value.id === "AB15") {
            return (
              <section className="news-section ">
                <div
                  className="sec-bg"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/images/about/${value.image})`,
                  }}></div>
                <div className="auto-container">
                  <div className="sec-title text-center">
                    <div className="sub-titl text-gray-500">{value.title}</div>
                    <h2>{html(value.content)}</h2>
                  </div>

                  <div className="row">

                    {blog.map((value) => {
                      return (
                        <div className="news-block-one col-lg-3">
                          <div className="inner-box">
                            <div className="image">
                              <Link to={`/project-details/${value.slug}`}>
                                {value.image.map((img, index) => {
                                  if (index === 0) {
                                    return (
                                      <img
                                        style={{
                                          objectFit: "cover",
                                          height: "300px",
                                        }}
                                        src={`${process.env.REACT_APP_BASE_URL}/images/project/${img.image}`}
                                        alt=""
                                      />
                                    );
                                  }
                                })}
                              </Link>
                            </div>
                            <div className="lower-content">
                              <div className="category flex flex-col items-center justify-items-center">
                                {/* {value.category} 
                                <span className="text-xl">
                                  {moment(value.createdAt).format("DD")}
                                </span>
                                <span className="text-xs">
                                  {moment(value.createdAt).format("MMM YYYY")}
                                </span>
                              </div>
                              <h3
                                className="-mt-5"
                                style={{ height: "70px", fontSize: "20px" }}>
                                <Link to={`/project-details/${value.slug}`}>
                                  {value.title.substring(0, 50)}
                                </Link>
                              </h3>

                              {html(value.content.substring(0, 100))}

                              <ul className="post-meta">
                                <li>
                                  <Link to={`/project-details/${value.slug}`}>
                                    <i className="far fa-clock"></i>
                                    Project {value.type}
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/project-details/${value.slug}`}>
                                    <i className="far fa-user"></i>By Admin
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            );
          }
        })} */}

        {/* <!-- Contact Section --> */}

        <section className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">
                {list.map((value) => {
                  if (value.id == "VM1" || value.id == "VM2") {
                    return <>{value.title + " & "}</>;
                  }
                })}

                {list.map((value) => {
                  if (value.id == "VM3" || value.id == "VM4") {
                    return <>{value.title + " "}</>;
                  }
                })}
              </div>
              <h2>
                {list.map((value) => {
                  if (value.id == "VM1" || value.id == "VM2") {
                    return <>{value.title + " & "}</>;
                  }
                })}

                {list.map((value) => {
                  if (value.id == "VM3" || value.id == "VM4") {
                    return <>{value.title + " "}</>;
                  }
                })}
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={require("../../assets/images/icons/icon-3.png")}
                      alt=""
                    />
                  </div>
                  <h2>
                    {" "}
                    {list.map((value) => {
                      if (value.id == "VM1" || value.id == "VM2") {
                        return <>{value.title + " "}</>;
                      }
                    })}
                  </h2>
                  <div className="text">
                    {visimisi.map((value) => {
                      if (
                        value.referensi == "VM1" ||
                        value.referensi == "VM2"
                      ) {
                        return <>{html(value.content)}</>;
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={require("../../assets/images/icons/icon-21.png")}
                      alt=""
                    />
                  </div>
                  <h2>
                    {list.map((value) => {
                      if (value.id == "VM3" || value.id == "VM4") {
                        return <>{value.title + " "}</>;
                      }
                    })}
                  </h2>
                  <div className="text">
                    {visimisi.map((value) => {
                      if (
                        value.referensi == "VM3" ||
                        value.referensi == "VM4"
                      ) {
                        return <>{html(value.content)}</>;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PortfolioFilter1 />

        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title">
                  <h2>{this.props.t("find")}</h2>
                </div>
                {/* <!-- Contact Form--> */}
                <section style={{ marginTop: "-40px" }} className="map-section">
                  <div className="map-column">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.7569691471444!2d106.7244308507425!3d-6.163293562098924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f78903d9487d%3A0x5d010484b93da3b0!2sPutra%20Dumas%20Lestari!5e0!3m2!1sid!2sid!4v1666151481618!5m2!1sid!2sid"
                      width="100%"
                      height="450"
                      frameborder="0"
                      style={{ border: 0, borderRadius: "30px" }}
                      allowfullscreen=""
                      aria-hidden="false"
                      tabIndex="0"></iframe>
                  </div>
                </section>
              </div>
              <div className="col-lg-6">
                <div className="sec-title"></div>
                <div className="contact-info">
                  <div className="border-box">
                    <div className="border_top"></div>
                    <div className="border_middile"></div>
                  </div>
                  <div className="row">
                    {list.map((value) => {
                      if (value.id === "FT5" || value.id === "FT6") {
                        return (
                          <div className="info-column col-md-6">
                            <div className="icon-box">
                              <div className="icon">
                                <span className="flaticon-call-1"></span>
                              </div>
                              <h3>{this.props.t("nohp")}</h3>
                              <ul>
                                <li
                                  style={{
                                    maxWidth: "250px",
                                    overflowWrap: "break-word",
                                  }}>
                                  {value.content}
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }

                      if (value.id === "FT3" || value.id === "FT4") {
                        return (
                          <div className="info-column col-md-6">
                            <div className="icon-box">
                              <div className="icon">
                                <span className="flaticon-email-6"></span>
                              </div>
                              <h3>{this.props.t("email")}</h3>
                              <ul>
                                <li
                                  style={{
                                    maxWidth: "250px",
                                    overflowWrap: "break-word",
                                  }}>
                                  {value.content}
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }

                      if (value.id === "FT7" || value.id === "FT8") {
                        return (
                          <div className="info-column col-md-6">
                            <div className="icon-box">
                              <div className="icon">
                                <span className="flaticon-location"></span>
                              </div>
                              <h3>{this.props.t("address")}</h3>

                              <ul>
                                <li
                                  style={{
                                    maxWidth: "250px",
                                    overflowWrap: "break-word",
                                  }}>
                                  {value.content}
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }

                      if (value.id === "FT9" || value.id === "FT10") {
                        return (
                          <div className="info-column col-md-6">
                            <div className="icon-box">
                              <div className="icon">
                                <span className="flaticon-worldwide"></span>
                              </div>
                              <h3>{this.props.t("fax")}</h3>
                              <ul>
                                <li
                                  style={{
                                    maxWidth: "250px",
                                    overflowWrap: "break-word",
                                  }}>
                                  {value.content}
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Award Section -->
        <section className="award-section pt-0">
          <div className="year">2020</div>
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Awards</div>
              <h2>Get Awards</h2>
            </div>
            <div className="award-image">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <img src={require("../../assets/images/resource/award-1.png")} alt="" />
                  </div>
                  <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <img src={require("../../assets/images/resource/award-2.png")} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 order-lg-2">
                  <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <img src={require("../../assets/images/resource/award-3.png")} alt="" />
                  </div>
                  <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <img src={require("../../assets/images/resource/award-4.png")} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 wow zoomIn" data-wow-delay="200ms" data-wow-duration="1200ms">
                  <div className="image">
                    <img src={require("../../assets/images/resource/award-5.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-image">
              <div className="image-one">
                <img src={require("../../assets/images/resource/image-7.jpg")} alt="" />
              </div>
              <div className="image-two">
                <img src={require("../../assets/images/resource/image-8.jpg")} alt="" />
              </div>
              <div className="image-three">
                <img src={require("../../assets/images/resource/image-9.jpg")} alt="" />
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  statistik: state.statistik,
  karyawan: state.karyawan,
  blog: state.blog,
  visimisi: state.visimisi,
});

const mapDispatchToProps = {
  getAllStatistik,
  getAllKaryawan,
  getAllBlog,
  getAllVisimisi,
};
export default connect(mapStateToProps, mapDispatchToProps)(translate()(Index));
