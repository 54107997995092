import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllStatistik = createAsyncThunk(
  "get_all_statistik",
  async ({ page, size, status }, thunkAPI) => {
    return api
      .get("statistik", {
        params: {
          pagination: {"page":1,"perPage":24},
          sort: {"field":"createdAt","order":"DESC"},
          filter: {"lang":localStorage.getItem("locale"), "status" : "Publish"},
        },
      })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          return thunkAPI.rejectWithValue(err.response.data.message);
        }
      );
  }
);

const statistik = createSlice({
  name: "statistik",
  initialState: {
    data: {
      id: "",
      title: "",
      counter: "",
      image: "",
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: ""
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // getAllStatistik
    builder.addCase(getAllStatistik.fulfilled, (state, {payload}) => {
      // console.log(payload.data)
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total_items;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllStatistik.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllStatistik.pending, (state, action) => {
      state.isFetching = true;
    });

    
  },
});

export default statistik.reducer;
