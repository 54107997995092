import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getAllBlogType } from "../app/module/blog/blogSlice";

import parse from "html-react-parser";
import { html } from "../utlis/html";

class HeaderNews extends Component {
  componentDidMount = () => {
    this.props.getAllBlogType({
      page: 1,
      size: 5,
      status: "Publish",
    });
  };

  render() {
    const { list } = this.props.referensi;
    const { list_type: blog } = this.props.blog;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };

    return (
      <>
        <section className="feature-section-two" style={{ height: "650px" }}>
          <Slider {...settings} className="theme_carousel">
            {blog.map((item, id) => (
              <div className="slide-item" key={id}>
                {item.image.map((value, index) => {
                  if (index === 0) {
                    return (
                      <div
                        className="slide-bg"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_BASE_URL +
                            "/images/project/" +
                            value.image +
                            ")",
                        }}></div>
                    );
                  }
                })}
                <div className="auto-container">
                  <div
                    style={{
                      background: "rgb(225, 225, 225, 0.5)",
                      height: "650px",
                    }}
                    className="content-box">
                    <div className="sec-title">
                      <div className="sub-title"> {item.category}</div>
                      <h2 className="text-sm md:text-2xl">
                        {item.title.substring(0, 50)} ...
                      </h2>
                    </div>
                    <div className="text">
                      {html(item.content.substring(0, 200))}
                    </div>
                    <div className="btn-box absolute">
                      <Link
                        to={`/project-details/${item.slug}`}
                        className="theme-btn btn-style-one">
                        <span className="btn-title">
                          {this.props.t("more")}
                        </span>
                      </Link>
                    </div>
                    {/* <div className="icon">
                      <span className="flaticon-insurance"></span>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  referensi: state.referensi,
  blog: state.blog,
});

const mapDispatchToProps = { getAllBlogType };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(HeaderNews));
