import { configureStore } from "@reduxjs/toolkit";
import sliderSlice from "./module/slider/sliderSlice";
import sliderMobileSlice from "./module/slider/sliderMobileSlice";
import referensiSlice from "./module/referensi/referensiSlice";
import statistikSlice from "./module/statistik/statistikSlice";
import photoSlice from "./module/photo/photoSlice";
import karyawanSlice from "./module/karyawan/karyawanSlice";
import blogSlice from "./module/blog/blogSlice";
import historySlice from "./module/history/historySlice";
import visimisiSlice from "./module/visimisi/visimisiSlice";
import bisnisSlice from "./module/bisnis/bisnisSlice";
import kategoriBisnisSlice from "./module/kategoribisnis/kategoriBisnisSlice";
import experienceSlice from "./module/experience/experienceSlice";
import feedbackSlice from "./module/feedback/feedbackSlice";
import lowonganSlice from "./module/lowongan/lowonganSlice";
import mailSlice from "./module/mail/mailSlice";
import divisiSlice from "./module/divisi/divisiSlice";
import sertifikatSlice from "./module/sertifikat/sertifikatSlice";
import memberSlice from "./module/member/memberSlice";
import pelamarSlice from "./module/pelamar/pelamarSlice";
import modalSlice from "./module/modal/modalSlice";
import bussinesCategorySlice from "./module/bussinescategory/bussinesCategorySlice";
import bussinesSlice from "./module/bussines/bussinesSlice";
import bussinesDetailSlice from "./module/bussinesdetail/bussinesDetailSlice";
import photoCategorySlice from "./module/photocategory/photoCategorySlice";

const store = configureStore({
  reducer: {
    bussines: bussinesSlice,
    bussinescategory: bussinesCategorySlice,
    bussinesdetail: bussinesDetailSlice,
    slider: sliderSlice,
    referensi: referensiSlice,
    statistik: statistikSlice,
    photo: photoSlice,
    karyawan: karyawanSlice,
    blog: blogSlice,
    history: historySlice,
    visimisi: visimisiSlice,
    bisnis: bisnisSlice,
    kategoribisnis: kategoriBisnisSlice,
    experience: experienceSlice,
    feedback: feedbackSlice,
    lowongan: lowonganSlice,
    mail: mailSlice,
    divisi: divisiSlice,
    sertifikat: sertifikatSlice,
    member: memberSlice,
    pelamar: pelamarSlice,
    modal: modalSlice,
    photoCategory: photoCategorySlice,
    sliderMobile: sliderMobileSlice
  },
});
export default store;
