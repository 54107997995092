import React, { useEffect, useState } from "react";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getAllPhoto } from "../app/module/photo/photoSlice";
import Carousel from "react-multi-carousel";
import { getAllPhotoCategory } from "../app/module/photocategory/photoCategorySlice";

const testimonialbg1 = require("./../../assets/images/background/image-9.jpg");

const selectorReferensi = (state) => state.referensi;
const selectorPhoto = (state) => state.photo;
const selectorPhotoCategory = (state) => state.photoCategory;

const settings = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const VisualProject = ({ t }) => {
  const dispatch = useDispatch();
  const [selectedPhotoCategory, setSelectedPhotoCategory] = useState("");

  const { list: referensi } = useSelector(selectorReferensi);
  const { list: photo } = useSelector(selectorPhoto);
  const { list: photoCategory } = useSelector(selectorPhotoCategory);

  useEffect(() => {
    dispatch(getAllPhoto({ page: 1, size: 100, status: "Publish" }));
    dispatch(getAllPhotoCategory({ page: 1, size: 100, status: "Publish" }));
  }, [dispatch]);

  const onClickPhotoCategory = async (value) => {
    dispatch(
      getAllPhoto({
        page: 1,
        size: 100,
        status: "Publish",
        photocategory: value.id,
      })
    );

    setSelectedPhotoCategory(value.id);
  };

  const onClickPhotoCategoryShowAll = async (value) => {
    dispatch(
      getAllPhoto({
        page: 1,
        size: 100,
        status: "Publish",
      })
    );

    setSelectedPhotoCategory("");
  };

  return (
    <>
      <section
        className="testimonial-section style-two"
        style={{
          backgroundImage: "url(" + testimonialbg1 + ")",
        }}>
        <div className="auto-container">
          <div style={{ color: "white" }} className="sec-title light">
            <div className="sub-title">Project</div>
            <h2>Visual Project</h2>
            {/* <h2>{t("feedback")}</h2> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            {/*    <Carousel
              controls="true"
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              transitionDuration={500}
              responsive={settings}>
              {photo.map((value, index) => {
                return (
                  <div
                    className="w-full h-52 md:h-80"
                    style={{
                      background: `url('${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}')`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}>
                    <div className="flex flex-row items-end h-full w-full">
                      <div
                        className={`flex flex-col w-full pb-3 pt-10 px-3 
                         bg-gradient-to-t from-black
                          text-gray-200`}>
                        <h2 className="text-base text-xl font-bold leading-5 flex flex-row">
                          <svg
                            className="stroke-current stroke-1 text-white w-4 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              fill-rule="evenodd"
                              d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {value.title}
                        </h2>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>

            {/* </Slider> */}
          </div>
        </div>
      </section>

      <section
        className="w-full"
        style={{
          marginTop: "-50px",
          background: "#f5f5f5",
          paddingBottom: "150px",
        }}></section>

      <section
        className="w-full"
        style={{
          marginTop: "-50px",
          background: "#f5f5f5",
          paddingBottom: "120px",
        }}>
        <div className="auto-container flex flex-col w-full items-start">
          <div className="grid grid-cols-3 md:grid-cols-6 gap-3 text-white">
            <div
              onClick={() => onClickPhotoCategoryShowAll()}
              key={`photoCategory-0`}
              style={{
                background: `
                    ${
                      selectedPhotoCategory === ""
                        ? "linear-gradient(0deg, rgba(0, 54, 164, 1) 3%, rgba(0, 126, 255, 0.8) 100%)"
                        : "linear-gradient(0deg, rgb(34, 40, 49) 30%, rgb(57, 62, 70, 0.9) 100%)"
                    }
                      
                    `,
                height: "50px",
                cursor: "pointer",
              }}
              className="relative text-start w-full  flex justify-center items-center ">
              <div className="p-2 text-start flex justify-center items-center ">
                <h3 className="font-bold text-center text-sm md:text-md">
                  Show All
                </h3>
              </div>
            </div>
            {photoCategory.map((value, index) => {
              return (
                <div
                  onClick={() => onClickPhotoCategory(value)}
                  key={`photoCategory-${index}`}
                  style={{
                    background: `
                    ${
                      selectedPhotoCategory === value.id
                        ? "linear-gradient(0deg, rgba(0, 54, 164, 1) 3%, rgba(0, 126, 255, 0.8) 100%)"
                        : "linear-gradient(0deg, rgb(34, 40, 49) 30%, rgb(57, 62, 70, 0.9) 100%)"
                    }
                      
                    `,
                    height: "50px",
                    cursor: "pointer",
                  }}
                  className="relative text-start w-full  flex justify-center items-center ">
                  <div className="p-2 text-start flex justify-center items-center ">
                    <h3 className="font-bold text-center text-sm md:text-md">
                      {value.title}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section
        className="w-full"
        style={{ marginTop: "-50px", background: "#f5f5f5" }}>
        <div className="auto-container flex flex-col w-full justify-center items-center">
          <div className="grid grid-cols-2 md:grid-cols-6 gap-3 justify-center text-white ">
            {photo.map((value, index) => {
              return (
                <div
                  key={`photo-${index}`}
                  className="w-full h-52 md:h-80"
                  style={{
                    backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}')`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}>
                  <div className="flex flex-row items-end h-full w-full">
                    <div
                      className={`flex flex-col w-full pb-3 pt-10 px-3 
                         bg-gradient-to-t from-black
                          text-gray-200`}>
                      <h3 className="text-base font-bold leading-5 text-xl">
                        {value.title}
                      </h3>
                      <div className="inline-flex items-center">
                        <span className="font-base text-md my-1 mr-1">
                          {value?._photo_category?.title}
                        </span>
                        <svg
                          className="stroke-current stroke-1 text-blue-600 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {photo.length < 1 && (
            <div className="w-full ">
              <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center  justify-center w-full h-64 p-1 border-2 border-white border-dashed rounded-lg cursor-pointer">
                  <div className="flex flex-col  bg-white items-center justify-center w-full h-full pt-5 pb-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#f5f5f5"
                      className="w-20 h-20">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      />
                    </svg>

                    <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
                      <span className="">Data not found</span>
                    </p>
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default translate()(VisualProject);
