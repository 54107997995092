import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllExperience } from "../app/module/experience/experienceSlice";
import VisualProject from "../element/VisualProject";
import HeaderBlack from "../layout/header-black";
import { toTitleCase } from "../utlis/html";

class OurExperience extends Component {
  componentDidMount = () => {
    this.props.getAllExperience({ page: 1, size: 1, status: "Publish" });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: experience } = this.props.experience;
    return (
      <>
        <HeaderBlack />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        <VisualProject />

        <section className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">{this.props.t("project")}</div>
              <h2>{this.props.t("client")}</h2>
            </div>
            <div className="row">
              {experience.map((value) => {
                return (
                  <div style={{ marginBottom: "20px" }} className="col-lg-3">
                    <div className="icon-box flex flex-row items-center">
                      <div className="icon">
                        <img
                          style={{ padding: "12px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/experience/${value.image}`}
                          alt=""
                        />
                      </div>
                      <div className="-mt-5">
                        <h4 style={{ fontSize: "15px", fontWeight: "700" }}>
                          {value.client}
                        </h4>
                        {/* <h4 style={{ fontSize: "15px" }}>
                          {value.project_name}
                        </h4> */}
                        {/* <h2 style={{ fontSize: "15px", marginTop: 3 }}>
                          <span
                            style={{ marginRight: 5 }}
                            className="flaticon-location"></span>
                          {toTitleCase(value?.prov?.name)}
                        </h2> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  experience: state.experience,
});

const mapDispatchToProps = { getAllExperience };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(OurExperience));
