import React, { useEffect } from "react";
import { I18n } from "react-polyglot";
import "./assets/css/bootstrap.css";
import "./assets/css/style2.css";
import "./assets/css/responsive2.css";
import "./assets/css/color-2.css";
import Router from "./markup/router";
import { useDispatch } from "react-redux";
import en from "./locale/en.json";
import id from "./locale/id.json";
import LocaleContext from "./locale/locale";
import { getAllReferensi } from "./markup/app/module/referensi/referensiSlice";
import { checkLogin } from "./markup/app/module/member/memberSlice";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const App = () => {
  const [locale, setLocale] = React.useState("en");
  const [messages, setMessages] = React.useState(id);
  const dispatch = useDispatch();

  useEffect(() => {

    if (localStorage.getItem("locale") === null || localStorage.getItem("locale") === "" || localStorage.getItem("locale") === undefined) {
      localStorage.setItem("locale", "en");
      setLocale("en");
    }

    async function lang() {
      if (locale === "en") {
        setMessages(en);
        localStorage.setItem("locale", "en");
      }

      // if (locale === "id") {
      //   setMessages(id);
      // }
    }

    lang();

    dispatch(getAllReferensi({ page: 1, size: 1, status: "Publish" }));
    dispatch(checkLogin());

  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <I18n locale={locale} messages={messages}>
        <>
          <div className="App">
            <Router />
            {/* <TawkMessengerReact
              propertyId={process.env.REACT_APP_TAWK_PROPERTYID}
              widgetId={process.env.REACT_APP_TAWK_WIDGETID}
            /> */}

          </div>
        </>
      </I18n>
    </LocaleContext.Provider>
  );
};

export default App;
