import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog, setPage } from "../app/module/blog/blogSlice";

function PaginatedItems({ itemsPerPage }) {
  const dispatch = useDispatch();
  const [itemOffset, setItemOffset] = useState(0);
  const { total_items, size } = useSelector((state) => state.blog);

  const endOffset = itemOffset + size;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  const pageCount = Math.ceil(parseInt(total_items) / size);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * size) % parseInt(total_items);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);

    dispatch(setPage(event.selected));
    dispatch(
      getAllBlog({ page: event.selected + 1, size: size, status: "Publish" })
    );
  };

  return (
    <>
      <div class="page-pagination">
        <ul class="clearfix">
          <ReactPaginate
            activeClassName="active"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </ul>
      </div>
    </>
  );
}

export default PaginatedItems;
