import React from "react";
import { useEffect } from "react";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { memberRegister, clearState } from "../app/module/member/memberSlice";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BeatLoader } from "react-spinners";

function RegisterForm({ t }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .min(11, "Phone length should be at least 11 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters"),
    conf_password: Yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const { isFetching, isSuccess, isError, errorMessage } = useSelector(
    (state) => state.member
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      history.push("/register-success");
    }

    if (isError) {
      console.log(errorMessage);
    }
  }, [isSuccess]);

  const doRegister = (data) => {
    dispatch(
      memberRegister({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        password: data.password,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(doRegister)}>
      <div className="row">
        <div className="col-md-12">
          <div className="field-label">
            {isError && <span style={{ color: "red" }}>{errorMessage}</span>}
          </div>
        </div>
      </div>
      <div className="row">
        <div style={{ marginTop: "25px" }} className="col-md-6">
          <div className="field-label">{t("first_name")}*</div>
          <div className="field-input">
            <input
              type="text"
              name="firstName"
              placeholder={t("first_name")}
              {...register("firstName")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.firstName && (
              <span style={{ color: "red" }}>{errors.firstName.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "25px" }} className="col-md-6">
          <div className="field-label">{t("last_name")} *</div>
          <div className="field-input">
            <input
              type="text"
              name="lastName"
              placeholder={t("last_name")}
              {...register("lastName")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.lastName && (
              <span style={{ color: "red" }}>{errors.lastName.message}</span>
            )}
          </div>
        </div>

        <div style={{ marginTop: "25px" }} className="col-md-12">
          <div className="field-label">Phone *</div>
          <div className="field-input">
            <input
              type="text"
              name="phone"
              placeholder={`Phone`}
              {...register("phone")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.phone && (
              <span style={{ color: "red" }}>{errors.phone.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "25px" }} className="col-md-12">
          <div className="field-label">{t("email")} *</div>
          <div className="field-input">
            <input
              type="email"
              name="email"
              placeholder={t("email")}
              {...register("email")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "25px" }} className="col-md-12">
          <div className="field-label">{t("password")} *</div>
          <div className="field-input">
            <input
              type="password"
              name="password"
              placeholder={t("password")}
              {...register("password")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.password && (
              <span style={{ color: "red" }}>{errors.password.message}</span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="col-md-12">
          <div className="field-input">
            <input
              type="password"
              name="conf_password"
              placeholder={t("conf_password")}
              {...register("conf_password")}
              style={{ marginBottom: "-2px" }}
            />
            {errors.conf_password && (
              <span style={{ color: "red" }}>
                {errors.conf_password.message}
              </span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "35px" }} className="col-md-12">
          <button type="submit" className="theme-btn btn-style-one">
            <span style={{ color: "white" }} className="btn-title">
              {isSuccess ? t("success") : t("daftar")}
              {isFetching && <BeatLoader color="#fff" />}
            </span>
          </button>
        </div>
        <div style={{ marginTop: "10px" }} className="col-md-12">
          <a
          // onClick={() => this.langSwitcher("en")}
          >
            <span className="btn-title">
              {t("have_account")} <Link to={"/login"}>{t("masuk")}</Link>
            </span>
          </a>
        </div>
      </div>
    </form>
  );
}

export default translate()(RegisterForm);
