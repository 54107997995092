import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate } from "react-polyglot";

class Menu extends Component {
  render() {
    return (
      <>
        <div
          // collapse
          className=" navbar-collapse show clearfix"
          id="navbarSupportedContent">
          <ul className="navigation clearfix ">
            <li>
              <Link to={"/"}>{this.props.t("home")}</Link>
            </li>
            <li>
              <Link to={"/about"}>{this.props.t("about")}</Link>
            </li>
            <li>
              <Link to={"/services"}>{this.props.t("bisnis")}</Link>
            </li>
            <li>
              <Link to={"/experience"}>{this.props.t("experience")}</Link>
            </li>
            <li>
              <Link to={"/contact"}>{this.props.t("contact")}</Link>
            </li>
            {/* <li>
              <Link to={"/projects"}>{this.props.t("news")}</Link>
            </li> */}
            <li>
              <Link to={"/careers"}>{this.props.t("karir")}</Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default translate()(Menu);
