import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { getAllPhoto } from "../app/module/photo/photoSlice";

const ModalImage_content = [
  {
    thumb: require("./../../assets/images/shop/1.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
  {
    thumb: require("./../../assets/images/shop/2.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "One of the best ux book",
  },
  {
    thumb: require("./../../assets/images/shop/3.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
  {
    thumb: require("./../../assets/images/shop/4.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
  {
    thumb: require("./../../assets/images/shop/1.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
  {
    thumb: require("./../../assets/images/shop/2.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "One of the best ux book",
  },
  {
    thumb: require("./../../assets/images/shop/3.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
  {
    thumb: require("./../../assets/images/shop/4.jpg"),
    price: "$250",
    btn: "Add To Cart",
    tag: "Business",
    title: "Office Chair",
  },
];

class ModalImage extends Component {
  componentDidMount = () => {
    this.props.getAllPhoto({ page: 1, size: 1, status: "Publish" });
  };

  render() {
    const { list: photo } = this.props.photo;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <section style={{ padding: "20px" }} className="">
          <div className="row">
            <div className="col-lg-12">
              <Slider {...settings} className="theme_carousel">
                {photo.map((value, id) => (
                  <div className="slide-item" key={id}>
                    <div className="single-product-item">
                      <div className="img-holder">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
                          alt="Awesome Product"
                        />
                        <div className="overlay-btn"></div>
                      </div>
                      <div className="title-holder">
                        <div className="static-content">
                          <h3 className="title">{value.title}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  photo: state.photo,
});

const mapDispatchToProps = { getAllPhoto };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(ModalImage));
