import { createSlice } from "@reduxjs/toolkit";

const modal = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    isBussinesOpen: false,
    isBussinesData: {
      image: "",
      title: "",
      content: "",
    },
  },
  reducers: {
    toggle: (state, action) => {
      state.isOpen = !state.isOpen;
      state.isBussinesOpen = false;
    },
    toggleBussines: (state, action) => {
      state.isBussinesOpen = !state.isBussinesOpen;
      state.isOpen = false;
      state.isBussinesData = action.payload;
    },
  },
});

export const { toggle, toggleBussines } = modal.actions

export default modal.reducer;
