import moment from "moment";
import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getBlog } from "../app/module/blog/blogSlice";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import { html } from "../utlis/html";
import Carousel from "react-multi-carousel";

const settings = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class BlogDestails extends Component {
  constructor(props) {
    super(props);
    this.state = { slug: "" };
  }

  componentDidMount = () => {
    if (this.props.match.params) {
      this.setState({ ...this.state, slug: this.props.match.params.slug });
      this.props.getBlog({ slug: this.props.match.params.slug });
    }
  };

  render() {
    const { list } = this.props.referensi;
    const { data, isFetching } = this.props.blog;

    return (
      <>
        <HeaderBlack />

        {/* Search Popup */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        {/* Sidebar Page Container */}
        <div
          style={{
            marginTop: "-60px",
            paddingBottom: "100px",
            background: "#f5f5f5",
          }}>
          <div className="sidebar-page-container">
            <div className="auto-container">
              <div className="row">
                <div className="col-lg-4">
                  <Carousel
                    controls="true"
                    // className="slider-wrapper"
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    transitionDuration={500}
                    responsive={settings}>
                    {!isFetching &&
                      data.image.map((img, index) => {
                        return (
                          <img
                            key={"image_pproject" + index}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={`${process.env.REACT_APP_BASE_URL}/images/project/${img.image}`}
                            alt=""
                          />
                        );
                      })}
                  </Carousel>
                </div>
                <div className="col-lg-8">
                  {/* News Block Two */}
                  <div className="news-block-two blog-single-post">
                    <div className="inner-box">
                      <div className="lower-content">
                        <div>
                          <div className="category">{data.category}</div>
                          <ul className="post-meta">
                            <li>
                              <Link to={"/#"}>
                                <i className="far fa-calendar-alt"></i>
                                {moment(data.createdAt).format("d MMM YYYY")}
                              </Link>
                            </li>
                          </ul>
                          <h2>{data.title}</h2>
                          <div className="text">
                            {html(data.content.substring(0, 200))}
                          </div>

                          <div className="post-share">
                            <div className="left-column">
                              <h4>Releted</h4>
                              <ul className="tag-list clearfix">
                                <li>
                                  <Link>
                                    <span>{data.category}</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  blog: state.blog,
});

const mapDispatchToProps = { getBlog };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(BlogDestails));
