import React, { Component } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-circular-progressbar/dist/styles.css";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllBisnis } from "../app/module/bisnis/bisnisSlice";
import { getAllBussinesCategory } from "../app/module/bussinescategory/bussinesCategorySlice";
import { getAllBussinesDetail } from "../app/module/bussinesdetail/bussinesDetailSlice";
import { getAllBussines } from "../app/module/bussines/bussinesSlice";
import { getAllKategoriBisnis } from "../app/module/kategoribisnis/kategoriBisnisSlice";
import { toggleBussines } from "../app/module/modal/modalSlice";
import CaseStudy from "../element/case-study";
import PaginatedBisnisItems from "../element/paginatebisnis";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import { html } from "../utlis/html";
import Slider from "react-slick";

const helpbg = require("./../../assets/images/background/image-18.jpg");
const teambg1 = require("./../../assets/images/background/image-3.jpg");
const newsbg1 = require("./../../assets/images/background/image-4.jpg");
const wellcomebg1 = require("./../../assets/images/resource/image-1.jpg");

const people = [
  "Durward Reynolds",
  "Kenton Towne",
  "Therese Wunsch",
  "Benedict Kessler",
  "Katelyn Rohan",
];
class ServicesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      selectedBussines: "",
      selectedBussinesCategory: "",
      brosur: "",
    };
  }

  componentDidMount = async () => {
    const { page, size } = this.props.bisnis;

    // if (this.props.match.params) {
    //   this.setState({ ...this.state, slug: this.props.match.params.slug });

    //   this.props.getAllBisnis({
    //     page: page,
    //     size: size,
    //     status: "Publish",
    //     kategori: this.props.match.params.slug,
    //   });
    // } else {
    //   this.props.getAllBisnis({ page: page, size: size, status: "Publish" });
    // }

    // await this.props.getAllKategoriBisnis({
    //   page: 1,
    //   size: 1,
    //   status: "Publish",
    // });

    await this.props.getAllBussines({ page: 1, size: 100, status: "Publish" });

    const { list: bussines } = this.props.bussines;

    await this.props.getAllBussinesCategory({
      page: 1,
      size: 100,
      status: "Publish",
      bisnis: bussines[0].id || "a",
    });

    const { list: bussinescategory } = this.props.bussinescategory;

    this.props.getAllBussinesDetail({
      page: 1,
      size: 100,
      status: "Publish",
      bisnis_category: bussinescategory[0]?.id || "a",
    });

    if (this.state.selectedBussines === "") {
      this.setState({ ...this.state, selectedBussines: bussines[0] });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.setState({ ...this.state, slug: this.props.match.params.slug });
      this.props.getAllBisnis({
        page: 1,
        size: 12,
        status: "Publish",
        kategori: this.props.match.params.slug,
      });
    }
  }

  toggleModalBussines = (value) => {
    this.props.toggleBussines(value);
  };

  onClickBussines = async (value) => {
    this.setState({ ...this.state, selectedBussines: value });

    await this.props.getAllBussinesCategory({
      page: 1,
      size: 100,
      status: "Publish",
      bisnis: value.id,
    });

    const { list: bussinescategory } = this.props.bussinescategory;

    this.props.getAllBussinesDetail({
      page: 1,
      size: 100,
      status: "Publish",
      bisnis_category: bussinescategory[0]?.id || "a",
    });
  };

  onChangeBussinesCategory = (event) => {
    this.setState({
      ...this.state,
      selectedBussinesCategory: event.target.value,
    });

    this.props.getAllBussinesDetail({
      page: 1,
      size: 100,
      status: "Publish",
      bisnis_category: event.target.value,
    });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: bisnis } = this.props.bisnis;
    const { list: bussines } = this.props.bussines;
    const { list: bussinescategory } = this.props.bussinescategory;
    const { list: bussinesdetail } = this.props.bussinesdetail;
    const { list: kategoribisnis } = this.props.kategoribisnis;
    const slug = this.state.slug;

    return (
      <>
        <HeaderBlack />

        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        <div
          style={{
            // marginTop: "-50px",
            background: "#f5f5f5",
            paddingBottom: "10px",
          }}
          className="-mt-20">
          <div style={{ background: "#f5f5f5" }} className="auto-container">
            <section className="cases-section">
              <div className="case-tabs">
                <div className="container-fluid">
                  <div className="sec-title text-center">
                    <div className="sub-title">{this.props.t("photo")}</div>
                    <h3 className="text-2xl md:text-4xl font-bold">
                      {this.props.t("bisnis")}{" "}
                    </h3>
                  </div>
                  <div className="case-tab-wrapper">
                    <div className="case-tabs-content">
                      <div className="case-tab active-tab">
                        {/* <CaseStudy /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section
          className="w-full"
          style={{ marginTop: "-50px", background: "#f5f5f5" }}>
          <div className="auto-container flex flex-col w-full justify-center items-center">
            <div className="grid grid-cols-3 md:grid-cols-6 justify-center text-white ">
              {/*  */}
              {bussines.map((value) => {
                if (value.image) {
                  return (
                    <>
                      <div
                        className="w-full h-52 md:h-80"
                        style={{
                          backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/images/bussines/${value.image}')`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}>
                        <div className="flex flex-row items-end h-full w-full">
                          <div
                            className={`flex flex-col w-full pb-3 pt-10 px-3 
                          ${
                            this.state.selectedBussines?.id === value.id
                              ? "bg-gradient-to-t from-blue-800"
                              : "bg-gradient-to-t from-black"
                          }
                          text-gray-200`}>
                            <h3 className="text-base font-bold leading-5 ">
                              {value.title}
                            </h3>
                            <div className="inline-flex items-center">
                              <span className="font-base text-xs my-1 mr-1">
                                {value.content}
                              </span>
                              <svg
                                className="stroke-current stroke-1 text-blue-600 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fill-rule="evenodd"
                                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <button
                              onClick={() => this.onClickBussines(value)}
                              className="bg-transparent hover:bg-blue-200 text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded-xl">
                              Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
                return (
                  <>
                    <div
                      style={{
                        background: `${
                          this.state.selectedBussines?.id === value.id
                            ? "linear-gradient(0deg, rgba(0, 54, 164, 1) 3%, rgba(0, 126, 255, 0.8) 100%)"
                            : "linear-gradient(0deg, rgb(34, 40, 49) 30%, rgb(57, 62, 70, 0.9) 100%)"
                        } `,
                      }}
                      // style={{
                      //   background:
                      //     "linear-gradient(0deg, rgba(0, 54, 164, 1) 3%, rgba(0, 126, 255, 0.8) 100%)",
                      // }}
                      className="relative text-start w-full h-52 md:h-80">
                      <div className="p-3 bottom-0 text-start">
                        <h3 className="font-bold text-start text-sm md:text-xl">
                          {value.title}
                        </h3>
                        <p className="text-start text-xs md:text-sm leading-1">
                          {value.content}
                        </p>
                      </div>
                      <div className="absolute bottom-0 p-3 w-full">
                        <button
                          onClick={() => this.onClickBussines(value)}
                          className="w-full bg-transparent hover:bg-blue-200 text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded-xl">
                          Detail
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="flex flex-row justify-center items-center mt-10">
              <div className="text-center font-bold">
                <h3 className="text-2xl md:text-4xl font-bold">
                  {this.state.selectedBussines !== "" ? (
                    <>{this.state.selectedBussines?.title}</>
                  ) : (
                    <>{bussines[0]?.title}</>
                  )}
                </h3>
                <p className="">
                  {this.state.selectedBussines !== "" ? (
                    <>{this.state.selectedBussines?.content}</>
                  ) : (
                    <>{bussines[0]?.content}</>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section style={{ background: "#f5f5f5" }} className="flex w-full">
          <div className="w-full auto-container">
            {bussinescategory.length > 0 && (
              <div className="showing-result-shorting">
                <div className="shorting">
                  <select
                    className="selectmenu"
                    onChange={(e) => this.onChangeBussinesCategory(e)}
                    value={this.state.selectedBussinesCategory}>
                    {bussinescategory.map((value, index) => {
                      return <option value={value.id}>{value.title}</option>;
                    })}
                  </select>
                </div>
                <div className="showing">
                  {bussinescategory.map((value, index) => {
                    if (value.brosur) {
                      return (
                        <a
                          href={`${process.env.REACT_APP_BASE_URL}/images/bussinescategorybrosur/${value.brosur}`}
                          target="_blank"
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                          rel="noreferrer">
                          <svg
                            className="fill-current w-4 h-4 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                          </svg>
                          <span>Download Brosur {value.title}</span>
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
            )}

            {/* <Slider
              centerMode={false}
              infinite={false}
              speed={500}
              slidesToShow={5}
              slidesToScroll={1}>
              {bussinescategory.map((value) => {
                if (value.image) {
                  return (
                    <div className="p-1">
                      <div
                        className="w-full mr-5 rounded-xl"
                        style={{
                          backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/images/bussinescategory/${value.image}')`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}>
                        <div className="flex flex-row items-end h-full w-full">
                          <div className="rounded-xl flex flex-row justify-between w-full py-3 px-3 bg-gradient-to-r from-white text-gray-700">
                            <div>
                              <h3 className="text-base font-bold leading-6">
                                {value.title}
                              </h3>
                              <span className="font-base text-xs my-1 mr-1">
                                {value.content}
                              </span>
                            </div>
                            <div className="flex items-center justify-center">
                              <button
                                onClick={() =>
                                  this.onClickBussinesCategory(value)
                                }
                                style={{ border: "2px solid #444444" }}
                                className="font-semibold py-1 px-4 rounded-xl">
                                Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="p-1">
                    <div
                      style={{
                        background:
                          "linear-gradient(0deg, rgb(34, 40, 49) 30%, rgb(57, 62, 70, 0.9) 100%)",
                      }}
                    
                      className="relative text-start w-full text-white rounded-xl ">
                      <div className="rounded-xl flex flex-row justify-between w-full py-3 px-3 ">
                        <div>
                          <h3 className="text-base font-bold leading-6">
                            {value.title}
                          </h3>
                          <span className="font-base text-xs my-1 mr-1">
                            {value.content}
                          </span>
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            onClick={() => this.onClickBussinesCategory(value)}
                            style={{ border: "2px solid #eeeeee" }}
                            className="font-semibold py-1 px-4 rounded-xl">
                            Detail
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                );
              })}
            </Slider> */}
          </div>
        </section>
        {/* <!-- Sidebar Page Container --> */}
        <section
          style={{ marginTop: "-50px", background: "#f5f5f5" }}
          className="flex justify-center">
          <div className="auto-container w-full">
            <div className="row">
              {bussinesdetail.map((value, index) => {
                return (
                  <div
                    key={"bisnis" + index}
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      wordWrap: "break-word",
                    }}
                    onClick={() => this.toggleModalBussines(value)}
                    className="experience-block experience-block-hover col-md-3">
                    <div
                      style={{ borderRadius: "10px", height: "370px" }}
                      className="inner-box">
                      <div style={{ marginBottom: "15px" }} className="image">
                        <img
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "140px",
                            width: "150%",
                          }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/bussinesdetail/${value.image}`}
                          alt=""
                        />
                      </div>

                      <h3>{value.title}</h3>
                      <div>{value.content.substring(0, 150)}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <PaginatedBisnisItems
              param={this.props.match.params?.slug}
              itemsPerPage={10}
            />

            {bussinesdetail.length < 1 && (
              <div className="w-full ">
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center  justify-center w-full h-64 p-1 border-2 border-white border-dashed rounded-lg cursor-pointer">
                    <div className="flex flex-col  bg-white items-center justify-center w-full h-full pt-5 pb-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#f5f5f5"
                        className="w-20 h-20">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                        />
                      </svg>

                      <p className="mb-2 text-sm text-gray-600 dark:text-gray-400">
                        <span className="">Data not found</span>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bussines: state.bussines,
  bussinescategory: state.bussinescategory,
  bussinesdetail: state.bussinesdetail,
  bisnis: state.bisnis,
  kategoribisnis: state.kategoribisnis,
  referensi: state.referensi,
});

const mapDispatchToProps = {
  getAllBussinesDetail,
  getAllBussinesCategory,
  getAllBussines,
  getAllBisnis,
  getAllKategoriBisnis,
  toggleBussines,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(ServicesDetails));
