import { useEffect } from "react";
import "./modalcss.scss";
import xicon from "../../assets/images/xicon.png";
import ModalImage from "./image-modal";
const Modal = ({ onRequestClose }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        onRequestClose();
      }
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="modal__backdrop">
      <div className="modal__container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}>
          <h3 className="modal__title">Image</h3>
          <div style={{ cursor: "pointer" }} onClick={onRequestClose}>
            <img style={{ width: "30px" }} src={xicon} alt="close" />
          </div>
        </div>

        <ModalImage />

        {/* <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder medium" />
        <div className="placeholder" /> */}
      </div>
    </div>
  );
};

export default Modal;
