import parse from "html-react-parser";

function html(string) {
    if (string[0] === '&') {
        return parse(parse(string))
    }

    if (string[0] === '<') {
        return parse(string)
    }

    return string;
}

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export {
    html,
    toTitleCase
}