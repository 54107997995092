import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import ContactForm2 from "./ContactForm2";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Contact extends Component {
  render() {
    const { list } = this.props.referensi;

    return (
      <>
        <HeaderBlack />

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        {/* <!-- Map Section --> */}
        <section className="map-section">
          <div className="map-column">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.7569691471444!2d106.7244308507425!3d-6.163293562098924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f78903d9487d%3A0x5d010484b93da3b0!2sPutra%20Dumas%20Lestari!5e0!3m2!1sid!2sid!4v1666151481618!5m2!1sid!2sid"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"></iframe>
          </div>
        </section>

        {/* <!-- Contact Section Two --> */}
        <section className="contact-section-two">
          <div className="auto-container">
            <div className="contact-info-area">
              <div className="contact-info">
                <div className="row">
                  {list.map((value) => {
                    if (value.id == "FT5" || value.id == "FT6") {
                      return (
                        <div className="info-column col-lg-4">
                          <div className="icon-box">
                            <div className="icon">
                              <span className="flaticon-call-1"></span>
                            </div>
                            <h3>{this.props.t("nohp")}</h3>
                            <ul>
                              <li>{value.content}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {list.map((value) => {
                    if (value.id == "FT7" || value.id == "FT8") {
                      return (
                        <div className="info-column col-lg-4">
                          <div className="icon-box">
                            <div className="icon">
                              <span className="flaticon-location"></span>
                            </div>
                            <h3>{this.props.t("address")}</h3>

                            <ul>
                              <li>{value.content}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {list.map((value) => {
                    if (value.id == "FT3" || value.id == "FT4") {
                      return (
                        <div className="info-column col-lg-4">
                          <div className="icon-box">
                            <div className="icon">
                              <span className="flaticon-email-6"></span>
                            </div>
                            <h3>{this.props.t("email")}</h3>
                            <ul>
                              <li>{value.content}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="contact-form-area">
              <div className="sec-title text-center">
                <div className="sub-title">{this.props.t("contact")}</div>
                <h2>{this.props.t("help")}</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <ContactForm2 />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Contact));
