import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { getAllPhoto } from "../app/module/photo/photoSlice";

const testimonialbg1 = require("./../../assets/images/background/image-9.jpg");

const selectorReferensi = (state) => state.referensi;
const selectorPhoto = (state) => state.photo;

const settings = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const PortfolioFilter1 = ({ t }) => {
  const dispatch = useDispatch();

  const { list: referensi } = useSelector(selectorReferensi);
  const { list: photo } = useSelector(selectorPhoto);

  useEffect(() => {
    dispatch(getAllPhoto({ page: 1, size: 100, status: "Publish" }));
  }, []);

  return (
    <>
      <section
        className="testimonial-section  style-two"
        style={{
          backgroundImage: "url(" + testimonialbg1 + ")",
        }}>
        <div className="auto-container">
          <div style={{ color: "white" }} className="sec-title light">
            <div className="sub-title">Project</div>
            <h2>Visual Project</h2>
            {/* <h2>{t("feedback")}</h2> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <Carousel
              controls="true"
              // className="slider-wrapper"
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              transitionDuration={500}
              responsive={settings}>
              {photo.map((value, index) => {
                return (
                  // <div className="case-block-one">
                  //   <div className="inner-box">
                  //     <div className="image">
                  //       <img
                  //         style={{
                  //           width: "100%",
                  //           height: "500px",
                  //           objectFit: "cover",
                  //         }}
                  //         src={`${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}`}
                  //         alt=""
                  //       />
                  //     </div>
                  //     <div
                  //       className="overlay md:w-80"
                  //       style={{
                  //         background: "rgb(255,255,255,0.8)",
                  //       }}>
                  //       <div>
                  //         <h5 style={{ color: "GrayText" }}>
                  //           <Link to={"/#"}>{`Visual Project`}</Link>
                  //         </h5>
                  //         <h2 style={{ color: "#333333" }}>{value.title}</h2>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div
                    className="w-full h-52 md:h-80"
                    style={{
                      background: `url('${process.env.REACT_APP_BASE_URL}/images/photo/${value.image}')`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}>
                    <div className="flex flex-row items-end h-full w-full">
                      <div
                        className={`flex flex-col w-full pb-3 pt-10 px-3 
                         bg-gradient-to-t from-black
                          text-gray-200`}>
                        <h2 className="text-base text-xl font-bold leading-5 flex flex-row">
                          <svg
                            className="stroke-current stroke-1 text-white w-4 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              fill-rule="evenodd"
                              d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          {value.title}
                        </h2>
                        <div className="inline-flex items-center">
                          <span className="font-base text-md my-1 mr-1">
                            {value?._photo_category?.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
            {/* </Slider> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default translate()(PortfolioFilter1);
