import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axios/index";

export const getAllReferensi = createAsyncThunk("get_all_referensi", async ({ page, size, status }, thunkAPI) => {
  return api
    .get("referensi", {
      params: {
        pagination: {"page":1,"perPage":100},
        sort: {"field":"createdAt","order":"DESC"},
        filter: {"lang":localStorage.getItem("locale"), "status" : "Publish"},
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      }
    );
});

const slider = createSlice({
  name: "slider",
  initialState: {
    data: {
      id: "",
      ref_group: "",
      title: "",
      content: "",
      image: "",
      lang: "",
      status: "",
      createdAt: "",
      updatedAt: "",
    },
    list: [],
    current_page: 0,
    total_items: 0,
    total_pages: 0,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // getAllReferensi
    builder.addCase(getAllReferensi.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.list = payload.data;
      state.current_page = payload.current_page;
      state.total_items = payload.total_items;
      state.total_pages = payload.total_pages;
      return state;
    });
    builder.addCase(getAllReferensi.rejected, (state, payload) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    });
    builder.addCase(getAllReferensi.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

export default slider.reducer;
