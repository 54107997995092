import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";

class Whatsapp extends Component {
  render() {
    const { list } = this.props.referensi;

    return (
      <>
        {list.map((value, index) => {
          if (value.id === "FT11" || value.id === "FT12") {
            return (
              <div key={"wa" + index} className="backtotop2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/${value.content}`}>
                  <img src="https://img.icons8.com/office/60/000000/whatsapp.png" />
                </a>
              </div>
            );
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  blog: state.blog,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Whatsapp));
