import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BackToTop from "./layout/backToTop";
import Whatsapp from "./layout/whatsapp";

import About from "./pages/about";
import BlogDestails from "./pages/blog-details";
import Careers from "./pages/careers";
import careersDetails from "./pages/careers-details";
import careersDetailsApply from "./pages/careers-details-apply";
import Contact from "./pages/contact";
import Index from "./pages/index";
import BlogGrid from "./pages/news";
import ourExperience from "./pages/our-experience";
import ServicesDetails from "./pages/services-details";

import Login from "./pages/login";
import Register from "./pages/register";

import { useDispatch, useSelector } from "react-redux";
import { toggle, toggleBussines } from "./app/module/modal/modalSlice";
import Modal from "./element/modal";
import ModalBussines from "./element/modal-bussines";

import applySuccess from "./pages/apply-success";
import loginSuccess from "./pages/login-success";
import registerSuccess from "./pages/register-success";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import Footer from "./layout/footer";

function Router() {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.member);
  const { isBussinesOpen, isOpen } = useSelector((state) => state.modal);

  const toggleModal = () => {
    dispatch(toggle());
  };

  const toggleModalBussines = () => {
    dispatch(toggleBussines());
  };

  return (
    <BrowserRouter>
      <div className="page-wrapper">
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/services" component={ServicesDetails} />
          <Route path="/services-kategori/:slug" component={ServicesDetails} />
          <Route path="/experience" component={ourExperience} />
          {/* <Route path="/projects" component={BlogGrid} /> */}
          {/* <Route path="/project-details/:slug" component={BlogDestails} /> */}
          <Route path="/contact" component={Contact} />
          <Route path="/careers" component={Careers} />
          <Route path="/careers-details/:slug" component={careersDetails} />
          <Route
            path="/careers-details-apply/:slug"
            component={careersDetailsApply}
          />
          <Route path="/register-success" component={registerSuccess} />
          <Route path="/login-success" component={loginSuccess} />
          <Route path="/apply-success" component={applySuccess} />

          <ProtectedRoute path="/login" isAuthenticated={isLogin}>
            <Login />
          </ProtectedRoute>
          <ProtectedRoute path="/register" isAuthenticated={isLogin}>
            <Register />
          </ProtectedRoute>

          <PrivateRoute
            path="/dashboard"
            isAuthenticated={isLogin}></PrivateRoute>

          <Route path="/" exact component={Index} />
        </Switch>

        <BackToTop />
        <Whatsapp />
        {isOpen && <Modal onRequestClose={toggleModal} />}
        {isBussinesOpen && (
          <ModalBussines onRequestClose={toggleModalBussines} />
        )}
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
