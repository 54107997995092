import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import successsvg from "../../assets/images/success.svg";
import RegisterForm from "./RegisterForm";

class LoginSuccess extends Component {
  render() {
    const { list } = this.props.referensi;

    return (
      <>
        <HeaderBlack />
        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        <div
          style={{
            marginTop: "-50px",
            marginBottom: "-60px",
            // background: "#f5f5f5",
          }}>
          <div className="auto-container">
            <section className="cases-section">
              <div className="case-tabs">
                <div className="container-fluid">
                  <div className="sec-title text-center">
                    <div className="sub-title">{this.props.t("karir")}</div>
                    <h2>
                      {" "}
                      {this.props.t("masuk")} {this.props.t("success")}{" "}
                    </h2>

                    <img
                      src={successsvg}
                      style={{
                        width: "30%",
                        marginTop: "30px",
                        marginBottom: "30px",
                      }}
                    />
                    <br />

                    <Link to="/careers">
                      <div
                        style={{
                          marginTop: "30px",
                          marginBottom: "100px",
                        }}
                        className="theme-btn btn-style-one">
                        <span className="btn-title">
                          {this.props.t("to_apply")}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  referensi: state.referensi,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(LoginSuccess));
