import moment from "moment/moment";
import React, { Component } from "react";
import { Nav, Tab } from "react-bootstrap";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllLowongan } from "../app/module/lowongan/lowonganSlice";
import Brand1 from "../element/brand1";
import Footer from "../layout/footer";
import HeaderBlack from "../layout/header-black";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const touchbg = require("./../../assets/images/background/image-8.jpg");

class Careers extends Component {
  componentDidMount = () => {
    this.props.getAllLowongan({ page: 1, size: 12, status: "Publish" });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: lowongan } = this.props.lowongan;
    return (
      <>
        <HeaderBlack />

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html">
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section
          style={{ paddingTop: "40px", background: "#fff" }}
          className="experience-section"></section>

        <div
          style={{
            marginTop: "-50px",
            marginBottom: "-60px",
            background: "#f5f5f5",
          }}>
          <div style={{ background: "#f5f5f5" }} className="auto-container">
            <section className="cases-section">
              <div className="case-tabs">
                <div className="container-fluid">
                  <div className="sec-title text-center">
                    <div className="sub-title">{this.props.t("karir")}</div>
                    <h2> {this.props.t("job")} </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section
          style={{ background: "#f5f5f5", paddingBottom: "200px" }}
          className="careers-section">
          <div className="auto-container">
            <div className="row">
              {lowongan.map((value) => {
                return (
                  <div className="col-lg-4 col-md-6 career-block">
                    <div
                      style={{ background: "#fff", borderRadius: "30px" }}
                      className="inner-box">
                      <div className="time">{value.job_function}</div>
                      <h3>{value.title}</h3>

                      <ul style={{ marginBottom: "30px" }} className="info">
                        <li>
                          <strong>{this.props.t("start_date")} : </strong>{" "}
                          {moment(value.start_date).format("d MMM YYYY")}
                        </li>
                        <li>
                          <strong>{this.props.t("end_date")}:</strong>{" "}
                          {moment(value.end_date).format("d MMM YYYY")}
                        </li>
                      </ul>
                      <div className="flex flex-row items-center gap-5">
                        {moment().format("d MMM YYYY") <
                        moment(value.end_date).format("d MMM YYYY") ? (
                          <button className="flex flex-row gap-2 rounded-full bg-red-500 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-red-600 active:bg-blue-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                            Closed
                          </button>
                        ) : (
                          <button className="flex flex-row gap-2 rounded-full bg-green-500 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-blue-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                            Open
                          </button>
                        )}

                        <Link to={`/careers-details/${value.slug}`}>
                          <div className="whitespace-nowrap rounded-full bg-blue-600 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700">
                            <span
                              style={{ color: "#fff" }}
                              className="btn-title">
                              {this.props.t("more")}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* <!-- Services Section Five --> */}
        {/* <section className="services-section-five gray-bg">
          <div className="auto-container">
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-team"></span>
                      </div>
                      <h5>Management</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-money"></span>
                      </div>
                      <h5>TAX Consume</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-assets"></span>
                      </div>
                      <h5>Finance & Strategy</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-world"></span>
                      </div>
                      <h5>Best Advice</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-money-1"></span>
                      </div>
                      <h5>Insurance</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sixth">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-notebook"></span>
                      </div>
                      <h5>Criminal Case</h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section> */}

        {/* <section className="services-section-two services-section-careers">
          <div className="auto-container">
            <div className="wrapper-box">
              <div
                className="left-column"
                style={{ backgroundImage: "url(" + touchbg + ")" }}>
                <div className="sec-title light">
                  <div className="sub-title">Get In Touch</div>
                  <h2>
                    Get Us Here Or <br /> Share Details
                  </h2>
                </div>
                <div className="contact-form">
                  <form
                    method="post"
                    action="http://azim.commonsupport.com/Finandox/sendemail.php"
                    id="contact-form">
                    <div className="row clearfix">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          name="username"
                          placeholder="Enter name here......"
                          required=""
                        />
                        <i className="fas fa-user"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter email here......"
                          required=""
                        />
                        <i className="fas fa-envelope"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Enter message here......"></textarea>
                        <i className="fas fa-edit"></i>
                      </div>

                      <div className="col-md-12 form-group">
                        <button
                          className="theme-btn btn-style-one"
                          type="submit"
                          name="submit-form">
                          <span className="btn-title">Get In Touch</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="right-column">
                <div className="services-content">
                  <div className="sec-title">
                    <div className="sub-title">Our Services</div>
                    <h2>
                      What Actually We <br />
                      Do Here.
                    </h2>
                  </div>
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={require("../../assets/images/icons/icon-3.png")}
                        alt=""
                      />
                    </div>
                    <h2>Capital Market</h2>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={require("../../assets/images/icons/icon-4.png")}
                        alt=""
                      />
                    </div>
                    <h2>Insurance & Planning</h2>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src={require("../../assets/images/icons/icon-5.png")}
                        alt=""
                      />
                    </div>
                    <h2>Financial Strategy</h2>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  referensi: state.referensi,
  lowongan: state.lowongan,
});

const mapDispatchToProps = { getAllLowongan };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Careers));
