import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import { translate } from "react-polyglot";
import { getAllSertifikat } from "../app/module/sertifikat/sertifikatSlice";
const testimonialbg1 = require("./../../assets/images/background/image-9.jpg");

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  autoPlay: true,
  autoPlaySpeed: 2000,
  transitionDuration: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Sertifikat extends Component {
  componentDidMount = () => {
    this.props.getAllSertifikat({ page: 1, size: 1, status: "Publish" });
  };

  render() {
    const { list } = this.props.referensi;
    const { list: sertifikat } = this.props.sertifikat;

    return (
      <>
        <section
          className="testimonial-section style-two"
          style={{
            backgroundImage: "url(" + testimonialbg1 + ")",
          }}>
          <div className="auto-container">
            <div className="sec-title light">
              <div className="sub-title">{this.props.t("sertifikat")}</div>
              <h2>{this.props.t("sertifikat")}</h2>
              {/* <h2>{t("feedback")}</h2> */}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <Slider {...settings}>
                {sertifikat.map((image, index) => {
                  return (
                    <div key={index} className="case-block-one">
                      <div className="inner-box">
                        <div className="image">
                          <img
                            style={{
                              width: "100%",
                              // height: "500px",
                              objectFit: "cover",
                            }}
                            src={`${process.env.REACT_APP_BASE_URL}/images/sertifikat/${image.image}`}
                            alt=""
                          />
                        </div>
                        <div
                          className="overlay md:w-80"
                          style={{
                            background: "rgb(255,255,255,0.7)",
                          }}>
                          <div>
                            <h5 style={{ color: "GrayText" }}>
                              <Link to={"/#"}>Certificate</Link>
                            </h5>
                            <h3 className="text-xl font-bold" style={{ color: "#333333" }}>{image.title}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  sertifikat: state.sertifikat,
});

const mapDispatchToProps = { getAllSertifikat };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Sertifikat));
