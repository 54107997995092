import React, { Component } from "react";
import { translate } from "react-polyglot";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "./menu";
import { logout } from "../app/module/member/memberSlice";
// import iconlogin from "../../assets/images/iconlogin.png";

class HeaderBlack extends Component {
  state = {
    scrolled: false,
    lang: localStorage.getItem("locale") || "id",
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ ...this.state, scrolled: true });
    } else {
      this.setState({ ...this.state, scrolled: false });
    }
  };

  langSwitcher = (lang) => {
    localStorage.setItem("locale", lang);
    this.setState({
      ...this.state,
      lang: lang,
    });

    window.location.reload();
  };

  doLogout = () => {
    this.props.logout();
    window.location.reload();
  };

  render() {
    const { scrolled } = this.state;
    const { list } = this.props.referensi;
    const { isLogin } = this.props.member;

    return (
      <>
        <header
          className={scrolled ? "main-header fixed-header" : "main-header"}>
          <div className="header-top">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to={"/"}>
                        {list.map((value) => {
                          if (value.id === "LG1" || value.id === "LG2") {
                            return (
                              <img
                                className="w-40 md:w-80"
                                src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                                alt=""
                              />
                            );
                          }
                        })}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="top-middile">
                  {/* <div className="language">
                    <span>Language:</span>
                    <form action="#" className="language-switcher">
                      <Select options={options} />
                    </form>
                  </div> */}
                  <div
                    style={{ borderRight: "1px solid #444444" }}
                    className="contact-info">
                    {list.map((value, index) => {
                      if (value.id === "FT5" || value.id === "FT6") {
                        return (
                          <div key={"hb" + index} className="single-info">
                            <div className="icon-box">
                              <i
                                style={{ color: "#444444" }}
                                className="flaticon-call-1"></i>
                            </div>
                            <div style={{ color: "#444444" }} className="text">
                              {this.props.t("nohp")}
                            </div>
                            <h4>
                              <Link style={{ color: "#444444" }}>
                                {value.content}
                              </Link>
                            </h4>
                          </div>
                        );
                      }

                      if (value.id === "FT3" || value.id === "FT4") {
                        return (
                          <div className="single-info">
                            <div className="icon-box">
                              <i
                                style={{ color: "#444444" }}
                                className="flaticon-email-4"></i>
                            </div>
                            <div style={{ color: "#444444" }} className="text">
                              {this.props.t("email")}
                            </div>
                            <h4>
                              <Link style={{ color: "#444444" }}>
                                {value.content}
                              </Link>
                            </h4>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <div className="top-right">
                  {/* {isLogin ? (
                    <a
                      onClick={this.doLogout}
                      style={{
                        border: "1px solid rgba(0, 54, 164, 1)",
                        marginRight: "5px",
                        marginTop: "5px",
                      }}
                      className="theme-btn btn-style-two">
                      <span
                        style={{ color: "rgba(0, 54, 164, 1)" }}
                        className="btn-title">
                        Keluar
                      </span>
                    </a>
                  ) : (
                    <Link to="/register">
                      <div
                        style={{
                          border: "1px solid rgba(0, 54, 164, 1)",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        className="theme-btn btn-style-two">
                        <span
                          style={{ color: "rgba(0, 54, 164, 1)" }}
                          className="btn-title">
                          {this.props.t("daftar")}
                        </span>
                      </div>
                    </Link>
                  )} */}
                  <div className="top-right">
                    {isLogin ? (
                      <a className="py-4" onClick={this.doLogout}>
                        <span
                          className="flex flex-row items-center"
                          style={{
                            fontWeight: 600,
                            color: "rgba(0, 54, 164, 1)",
                          }}>
                          {/* <img
                            style={{
                              width: "25px",
                              marginRight: "10px",
                              transform: "rotate(180deg)",
                            }}
                            src={iconlogin}
                            alt=""
                          /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 mr-3">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          <div className="flex flex-col">
                            <span
                              style={{
                                fontWeight: 600,
                                color: "rgba(0, 54, 164, 1)",
                              }}>
                              {/* {this.props.t("daftar")} */}
                              Sign Out
                            </span>
                            <span className="text-xs">
                              {/* {this.props.t("to_apply")} */}
                            </span>
                          </div>
                        </span>
                      </a>
                    ) : (
                      <Link to="/register">
                        <span
                          className="flex flex-row items-center"
                          style={{ color: "rgba(0, 54, 164, 1)" }}>
                          {/* <img
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "10px",
                            }}
                            src={iconlogin}
                            alt=""
                          /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 mr-3">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          <div className="flex flex-col">
                            <span
                              style={{
                                fontWeight: 600,
                                color: "rgba(0, 54, 164, 1)",
                              }}>
                              {this.props.t("daftar")}
                            </span>
                            <span className="text-xs">
                              {this.props.t("to_apply")}
                            </span>
                          </div>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars py-3"></span>
                  </div>
                  <div className="auto-container">
                    <nav className="main-menu navbar-expand-md navbar-light">
                      <Menu />
                    </nav>

                    <ul className="social-links clearfix">
                      {list.map((value) => {
                        if (value.id === "FT1" || value.id === "FT2") {
                          return (
                            <li className="py-2">
                              <a
                                target="_blank"
                                href={`${value.content}`}
                                rel="noreferrer">
                                <span className="fab fa-facebook"></span>
                              </a>
                            </li>
                          );
                        }

                        if (value.id === "FT13" || value.id === "FT14") {
                          return (
                            <li className="py-2">
                              <a
                                target="_blank"
                                href={`${value.content}`}
                                rel="noreferrer">
                                <span className="fab fa-instagram"></span>
                              </a>
                            </li>
                          );
                        }

                        if (value.id === "FT15" || value.id === "FT16") {
                          return (
                            <li className="py-2">
                              <a
                                target="_blank"
                                href={`${value.content}`}
                                rel="noreferrer">
                                <span className="fab fa-linkedin"></span>
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }>
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to={"/"} title="">
                  {list.map((value) => {
                    if (value.id === "LG1" || value.id === "LG2") {
                      return (
                        <img
                          style={{ width: "260px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                          alt=""
                        />
                      );
                    }
                  })}
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to={"/"}>
                  {list.map((value) => {
                    if (value.id === "LG1" || value.id === "LG2") {
                      return (
                        <img
                          style={{ width: "180px" }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/logo/${value.image}`}
                          alt=""
                        />
                      );
                    }
                  })}
                </Link>
              </div>
              <div className="menu-outer">
                <Menu />
              </div>
              <div className="social-links">
                <ul className="clearfix">
                  {/* <li>
                    <Link to={"/#"}>
                      <span className="fab fa-twitter"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-facebook-square"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-pinterest-p"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-instagram"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-youtube"></span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  referensi: state.referensi,
  member: state.member,
});

// export default translate()(Header);
export default connect(mapStateToProps, { logout })(translate()(HeaderBlack));
