import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { translate } from "react-polyglot";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { createMail } from "../app/module/mail/mailSlice";

const selectorMail = (state) => state.mail;

function ContactForm2({ t }) {
  const dispatch = useDispatch();

  const { data, isFetching, isSuccess, isError } = useSelector(selectorMail);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmitMessage = async (data, e) => {
    Object.assign(data, { type: "in" });
    dispatch(createMail({ data }));
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit(onSubmitMessage)}>
        <div className="row clearfix">
          <div className="col-md-6 form-group">
            <span style={{ paddingLeft: "10px" }} for="name">
              {t("enter_name")}
            </span>
            <input
              style={{ borderRadius: "30px" }}
              type="text"
              placeholder={`${t("enter_name")}`}
              {...register("name", { required: true })}
            />
            <i style={{ marginTop: "-13px" }} className="fas fa-user"></i>
            {errors.name && (
              <span style={{ color: "red", paddingLeft: "10px" }}>
                {t("enter_name")}
              </span>
            )}
          </div>

          <div className="col-md-6 form-group">
            <span style={{ paddingLeft: "10px" }} for="email">
              {t("enter_name")}
            </span>
            <input
              style={{ borderRadius: "30px" }}
              type="email"
              name="email"
              placeholder={`${t("enter_mail")}`}
              {...register("email", { required: true })}
            />
            <i style={{ marginTop: "-13px" }} className="fas fa-envelope"></i>
            {errors.email && (
              <span style={{ color: "red", paddingLeft: "10px" }}>
                {t("enter_mail")}
              </span>
            )}
          </div>

          <div className="col-md-12 form-group">
            <span style={{ paddingLeft: "10px" }} for="message">
              Enter your message
            </span>
            <textarea
              name="content"
              style={{ borderRadius: "30px" }}
              {...register("content", { required: true })}
              placeholder={`${t("enter_message")}`}></textarea>
            <i style={{ marginTop: "-13px" }} className="fas fa-edit"></i>
            {errors.content && (
              <span style={{ color: "red", paddingLeft: "10px" }}>
                {t("enter_content")}
              </span>
            )}
          </div>

          <div className="col-md-12 form-group">
            <button className="theme-btn btn-style-one" type="submit">
              <span className="btn-title">
                {isSuccess ? t("success") : t("send")}
                {isFetching && <BeatLoader color="#fff" />}
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default translate()(ContactForm2);
